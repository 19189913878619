import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/login.css';
import '../css/home.css'
import '../css/registerBusiness.css';

import HomepageMenu from '../components/HomepageMenu';
import Footer from '../components/Footer';

import { Circles } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import firstImage from '../images/register.png'
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useTranslation } from 'react-i18next';

export default function RegisterBusiness() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [hCaptchaToken, setHCaptchaToken] = useState(null);
    const { t } = useTranslation();

    const sendVerificationLink = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sendVerificationEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            });
            if (!response.ok) {
                if (response.status === 404 || response.status === 401) {
                    console.log(response.status)
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            }
        } catch (error) {
            console.error('Failed to fetch:', error);
        }
    }

    const validateForm = () => {
        let tempErrors = {};
        let formIsValid = true;

        // Name validation
        if (!name) {
            formIsValid = false;
            tempErrors.name = 'Name is required';
        }

        // Email validation
        if (!email) {
            formIsValid = false;
            tempErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formIsValid = false;
            tempErrors.email = 'Email is not valid';
        }

        // Password validation
        if (!password) {
            formIsValid = false;
            tempErrors.password = 'Password is required';
        } else if (password.length < 8) {
            formIsValid = false;
            tempErrors.password = 'Password must be at least 8 characters long';
        }

        if (password !== confirmPassword) {
            formIsValid = false;
            tempErrors.confirmPassword = 'Passwords do not match';
        }

        setErrors(tempErrors);
        return formIsValid;
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        if (!hCaptchaToken) {
            alert('Please complete the hCaptcha.');
            setLoading(false);
            return;
        }
        if (validateForm()) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/registerBusiness`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ name, email, password })
                });

                if (!response.status === 200) {
                    setLoading(false);
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    if (response.status === 200) {
                        const data = await response.json();
                        setLoading(false);
                        await sendVerificationLink()
                        createBusiness(data.id);
                    } else if (response.status === 400) {
                        setLoading(false);
                        console.error('Failed to register business:');
                        setMessage('Failed to register business. Email not found.');
                    }  else if (response.status === 409) {
                        setLoading(false);
                        console.error('Failed to register business:');
                        setMessage('Failed to register business. Account is already registered but is disabled.');
                    }  else if (response.status === 410) {
                        setLoading(false);
                        console.error('Failed to register business:');
                        setMessage('Failed to register business. Account is already registered.');
                    }
                }
            } catch (error) {
                setLoading(false);
                console.error('Failed to register business:', error);
                setMessage('Failed to register business. Please try again.');
            }
        }
    };

    const createBusiness = async (businessId) => {
        
            try {
                const businessDetails = {
                    basics: {
                        businessName: name,
                        contactName: '',
                        contactNumber: '',
                        contactAddress: '',
                        contactEmail: email,
                        userId: businessId,
                    },
                    invoiceTemplateDetails: {
                        companyName: name,
                        contactNumber: '',
                        emailAddress: email,
                        regNumber: '',
                        vatNumber: '',
                        companyAddress: ''
                    },
                    integrations: {
                        backMarket: '',
                        refurbed: '',
                    }
                };

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/businesses`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(businessDetails)
                });
                if (response.status === 200) {
                    setMessage('Business registered successfully. Please verify the email to login!');
                    setName('');
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setTimeout(() => {
                        setMessage('');
                        navigate('/login');
                    }, 2000);
                } else {
                    setMessage('Failed to register business. Please try again.');
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error('Failed to register business:', error);
                setMessage('Failed to register business. Please try again.');
            }
    };
    

    return (
        <>
        <HomepageMenu />
        <Helmet>
                <title>{ t('signUp')}</title>
            <meta name="description" content="Start your 7-day free trial with Flexmerce and experience streamlined invoicing, reduced marketplace fees, and automated customer service solutions. Join us today!" />
        </Helmet>
        <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        >
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
            <div className="container text-center">
                <div className='row'>
                    <div className='col-sm-5 landing-container-image services-first-image-div' style={{marginLeft: "-50px !important"}}>
                    <img src={firstImage} alt='autopilot' className='services-first-image text-right' style={{marginLeft: "-50px !important"}}/>
                    </div>
                    <div className='col-sm-7'>
            
            <div className="new-login-card mt-5">
                    <h2 className='fw-bold'>{ t('signUp')}</h2>
                    <p className='text-dark mt-2'>
                    { t('start')}
                    </p>
                    <hr className='m-2'></hr>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                             <input
                             type="text"
                             value={name}
                             className={`form-control p-4 ${errors.name ? 'is-invalid' : ''}`}
                             onChange={e => setName(e.target.value)}
                             required
                             placeholder=""
                             />
                             <label className="floating-label-register">{ t('businessName')}</label>
                         </div>
                         <div className="form-group register-form-input">
                             <input
                                 type="email"
                                 value={email}
                                 onChange={e => setEmail(e.target.value)}
                                 className={`form-control p-4 ${errors.email ? 'is-invalid' : ''}`}
                                 placeholder=""
                                 autoComplete="new-password"
                             />
                             <div className="invalid-feedback">{errors.email}</div>
                             <label className="floating-label-register">{ t('emailAddress')}</label>
                         </div>
                         <div className="form-group position-relative register-form-input">
                             <input
                                 type={showPassword ? 'text' : 'password'}
                                 value={password}
                                 onChange={e => setPassword(e.target.value)}
                                 className={`form-control p-4 ${errors.password ? 'is-invalid' : ''}`}
                                 placeholder=""
                                 autoComplete="new-password"
                             />
                             <FontAwesomeIcon
                                 icon={showPassword ? faEyeSlash : faEye}
                                 className="password-toggle-icon"
                                 onClick={() => setShowPassword(!showPassword)}
                             />
                             <div className="invalid-feedback">{errors.password}</div>
                             <label className="floating-label-register">{ t('password')}</label>
                         </div>
                         <div className="form-group position-relative register-form-input">
                             <input
                                 type={showConfirmPassword ? 'text' : 'password'}
                                 value={confirmPassword}
                                 onChange={e => setConfirmPassword(e.target.value)}
                                 className={`form-control p-4 ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                 placeholder=""
                            />
                             <FontAwesomeIcon
                                 icon={showConfirmPassword ? faEyeSlash : faEye}
                                 className="password-toggle-icon"
                                 onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                             />
                             <div className="invalid-feedback">{errors.confirmPassword}</div>
                             <label className="floating-label-register">{ t('confirmPassword')}</label>
                         </div>
                            <HCaptcha
                                sitekey="9c00be40-4368-49b6-afee-cc7ed23e90a4"
                                onVerify={(token) => setHCaptchaToken(token)}
                                onError={(err) => console.error('hCaptcha error:', err)}
                                onExpire={() => setHCaptchaToken(null)}
                            />
                         {message && <div className="alert alert-success mt-3 text-center text-dark">{message}</div>}
                         <div className="form-group">
                             <button type="submit" className="btn btn-dark bg-blue w-100 p-3">{ t('register')}</button>
                         </div>
                    </form>
                    <p className='text-dark'>{ t('already')}<a href="/login" className='font-orange font-1rem'>{ t('loginHere')}</a></p>
                    <p className='text-dark'>
                    { t('afterTrial')}
                    </p>
                </div>
          </div>
                </div>
                
            </div>
            <Footer />
        </div>
        </>
    )
}