import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// Register required elements and scales
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);

const LineChart = ({ data, labels }) => {
    const { t } = useTranslation();
    const currentLanguage = i18next.language;
    const now = new Date();
    const monthName = now.toLocaleString(currentLanguage, { month: 'long' }); // Get the full name of the current month

    // Add the month name at the end of the labels array
    const chartLabels = [...labels, monthName];

    const chartData = {
        labels: chartLabels, // Array of dates plus the month name
        datasets: [
            {
                label: t('lineChart.invoicesCreated'),
                data: [...data, null], // Add a placeholder for the month name position
                fill: false,
                borderColor: '#ff8a00',
                tension: 0.25,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Allows the chart to fill the container
        scales: {
            x: {
                ticks: {
                    // Add conditional formatting for the last label (month name)
                    callback: function (value, index) {
                        return index === chartLabels.length - 1 ? chartLabels[index] : chartLabels[index];
                    },
                },
            },
            y: {
                ticks: {
                    // Ensure only integer values are displayed
                    callback: function (value) {
                        return Number.isInteger(value) ? value : '';
                    },
                    stepSize: 1, // Force steps to increment by 1
                },
                beginAtZero: true, // Start axis at zero
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
        },
    };

    return (
        <div style={{ height: '15rem', width: '100%' }}>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default LineChart;
