import { createSlice } from '@reduxjs/toolkit';

const getOnProfileFromStorage = () => {
  try {
    const storedValue = localStorage.getItem('isProfileSetup');
    return storedValue ? JSON.parse(storedValue) : false;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return false;
  }
};

const profileSetupSlice = createSlice({
  name: 'profileSetup',
  initialState: {
    isProfileSetup: getOnProfileFromStorage(),
  },
  reducers: {
    setProfileSetup: (state, action) => {
      state.isProfileSetup = action.payload;
      localStorage.setItem('isProfileSetup', JSON.stringify(action.payload));
    },
    syncProfileSetup: (state) => {
      state.isProfileSetup = getOnProfileFromStorage();
    },
  },
});

export const { setProfileSetup, syncProfileSetup } = profileSetupSlice.actions;
export default profileSetupSlice.reducer;
