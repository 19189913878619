import React, { useEffect, useRef, useState } from 'react';
import '../css/dashboard.css';
import axiosInstance from '../components/axio';
import { setProfileSetup } from "../redux/slices/profileSlice";
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Circles } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

export default function BusinessDetails() {
    const userId = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const hasFetched = useRef(false);
    const { t } = useTranslation();
    const [businessDetails, setBusinessDetails] = useState({
        basics: {
            businessName: '',
            contactName: '',
            contactNumber: '',
            contactAddress: '',
            contactEmail: '',
            userId: userId
        },
        invoiceTemplateDetails: {
            companyName: '',
            contactNumber: '',
            emailAddress: '',
            regNumber: '',
            vatNumber: '',
            companyAddress: ''
        },
        integrations: {
            backMarket: '',
            refurbed: '',
        }
    });

    useEffect(() => {
        if (!hasFetched.current) {
            // Run only once
            hasFetched.current = true;
            async function fetchBusinessDetails() {
                setLoading(true);
                if (userId) {
                    try {
                        const response = await axiosInstance.get(`/businesses/${userId}`);
                        if (response.status !== 200) {
                            throw new Error('Failed to fetch business data');
                        }
                        const data = await response.data;
                        setBusinessDetails(data);
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        console.error('Error:', error);
                    }
                }
            }
            fetchBusinessDetails();
        }
    }, [userId]);

    const handleInputChange = (section, key, value) => {
        setBusinessDetails(prevState => ({
            ...prevState,
            [section]: { ...prevState[section], [key]: value },
        }));
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        try {
            const response = await axiosInstance.put(`/businesses/${userId}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(businessDetails)
            });
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            dispatch(setProfileSetup(true));
            setLoading(false);
            window.close();
        } catch (error) {
            setLoading(false);
            console.error('Failed to update settings:', error);
            alert('Failed to update settings.');
        }
    };

    return (
        <div className='container mb-5'>
        <Helmet>
            <title>{ t('SettingsPage.businessDetails')}</title>
        </Helmet>
        {loading && (
            <div className='loader-overlay'>
                <div className='loader-container'>
                    <Circles
                        height="80"
                        width="80"
                        color="#ff8a00"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                </div>
            </div>
        )}
        <h2 className='mt-3 mb-4 fw-bold'>{ t('SettingsPage.settings')}</h2>
        <form onSubmit={handleSubmit}>
        <div className='card border-radius-20'>
            <h4 className='text-center mt-3'>{ t('SettingsPage.businessDetails')}</h4>
            <hr className='mr-5 ml-5'></hr>

            <div className='row'>
                <div className='col-sm-6'>
                    <div className="form-group m-1 p-1">
                    <input
                        type="text"
                        value={businessDetails.basics.businessName}
                        onChange={e => handleInputChange('basics', 'businessName', e.target.value)}
                        placeholder=""
                        className='form-control w-100'
                        required
                    />
                    <label className="floating-label-register">{ t('SettingsPage.businessName')}</label>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className="form-group m-1 p-1">
                    <input
                        type="text"
                        value={businessDetails.basics.contactName}
                        onChange={e => handleInputChange('basics', 'contactName', e.target.value)}
                        placeholder=""
                        className='form-control w-100'
                        required
                    />
                    <label className="floating-label-register">{ t('SettingsPage.contactName')}</label>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className="form-group m-1 p-1">
                    <input
                        type="text"
                        value={businessDetails.basics.contactNumber}
                        onChange={e => handleInputChange('basics', 'contactNumber', e.target.value)}
                        placeholder=""
                        className='form-control w-100'
                        required
                    />
                    <label className="floating-label-register">{ t('SettingsPage.contactNumber')}</label>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className="form-group m-1 p-1">
                    <input
                        type="email"
                        value={businessDetails.basics.contactEmail}
                        onChange={e => handleInputChange('basics', 'contactEmail', e.target.value)}
                        placeholder=""
                        className='form-control w-100'
                        required
                    />
                    <label className="floating-label-register">{ t('SettingsPage.emailAddress')}</label>
                    </div>
                </div>
                <div className='col-sm-12'>
                    <div className="form-group m-1 p-1">
                    <input
                        type="text"
                        value={businessDetails.basics.contactAddress}
                        onChange={e => handleInputChange('basics', 'contactAddress', e.target.value)}
                        placeholder=""
                        className='form-control w-100'
                        required
                    />
                    <label className="floating-label-register">{ t('SettingsPage.address')}</label>
                    </div> 
                </div>
            </div>
            </div>
            <div className='d-flex justify-content-center'>
                <input type="submit" className='btn btn-dark w-50 p-3 mt-5 mb-3 rounded-md bg-blue' value={ t('SettingsPage.saveSettings')}/>
            </div>
            </form>
            </div>
    );
}
