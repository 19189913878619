import React, { createContext, useContext, useState } from 'react';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return localStorage.getItem('isAuthenticated') === 'true';
    });

    const [userRole, setUserRole] = useState(() => {
        return localStorage.getItem('userRole') || 'user'; // Default to 'user' role
    });

    const login = (role = 'user') => {
        setIsAuthenticated(true);
        setUserRole(role);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userRole', role);
    };

    const logout = () => {
        setIsAuthenticated(false);
        setUserRole('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userRole');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userRole, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};


export const useAuth = () => useContext(AuthContext);
