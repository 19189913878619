import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import * as CiIcons from 'react-icons/ci';
import 'bootstrap'
import '../css/dashboard.css';
import CheckAuth from '../hooks/checkAuth';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../components/axio';
import { Circles } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

export default function ManageTaxOptions() {
    CheckAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [taxOptions, setTaxOptions] = useState([]);  // Initialize as an array
    const [loading, setLoading] = useState(false);
    const hasFetched = useRef(false);
    useEffect(() => {
        if (!hasFetched.current) {
            // Run only once
            hasFetched.current = true;
            async function fetchTaxOptions() {
                setLoading(true);
                const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
                if (userId) {
                    try {
                        const response = await axiosInstance.get(`/taxOption/${userId}`);
                        if (response.status!==200) {
                            throw new Error('Failed to fetch Tax options');
                        }
                        const data = await response.data;
                        setTaxOptions(Array.isArray(data) ? data : []);
                        setLoading(false);
                    } catch (error) {
                        console.error('Error:', error);
                        setLoading(false);
                    }
                }
            }
            fetchTaxOptions();
        }
    }, []);

    function handleAddTaxOptionBtnClick() {
        navigate(`/addUpdateTaxOption/new`);
    }
    const handleEditClick = (invoiceId) => {
        navigate(`/addUpdateTaxOption/edit/${invoiceId}`);
    };

    return (
        <div className='container mb-5'>
            <Helmet>
                <title>{ t('TaxOptionsPage.taxOptions')}</title>
            </Helmet>
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
            <h2 className='mt-3 mb-4 fw-bold'>{ t('TaxOptionsPage.manageTaxOptions')}</h2>

            <div className='d-flex justify-content-end mb-3'>
                    <button
                        type="button"
                        className='btn bg-blue btn-w25-w50 p-2 mb-2 mt-2 mr-3 rounded-md text-light'
                        onClick={handleAddTaxOptionBtnClick}>
                            { t('TaxOptionsPage.addTaxOption')}
                    </button>
            </div>
            
            <div className=''>  
                
                <table className='table-custom-radius text-center bg-transparent' id="main-table">
                    <thead>
                        <tr>
                            <th style={{width: '30%'}} className='text-light bg-blue'>{ t('TaxOptionsPage.title')}</th>
                            <th style={{width: '40%'}} className='text-light bg-blue'>{ t('TaxOptionsPage.invoiceName')}</th>
                            <th style={{width: '10%'}} className='text-light bg-blue'>{ t('TaxOptionsPage.percentage')}</th>
                            <th style={{width: '10%'}} className='text-light bg-blue'>{ t('TaxOptionsPage.active')}</th>
                            <th style={{width: '10%'}} className='text-light bg-blue'>{ t('TaxOptionsPage.options')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {taxOptions.map((taxOption, index) => {
                            return (
                                <tr key={index}>
                                    <td data-label={ t('TaxOptionsPage.title')}><b>{taxOption.optionTitle}</b></td>
                                    <td data-label={ t('TaxOptionsPage.invoiceName')}>{taxOption.invoiceName}</td>
                                    <td data-label={ t('TaxOptionsPage.percentage')}>{taxOption.percentage}%</td>
                                    <td data-label={ t('TaxOptionsPage.active')}>{taxOption.active === 1 ? '✔️' : '❌'}</td>
                                    <td data-label={ t('TaxOptionsPage.options')}>
                                        <button onClick={() => handleEditClick(taxOption._id)} style={{ border: 'none', background: 'none' }}>
                                            <CiIcons.CiEdit style={{ height: '30px', width: '30px' }} />
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    )
}