import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css';
import '../css/calculateSavings.css';
import '../css/services.css'
import 'bootstrap';
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

export default function CalculateSavings() {
  const [menuHeight, setMenuHeight] = useState(0);
  const [numberOfOrders, setNumberOfOrders] = useState('');
  const [averageProductAmount, setAverageProductAmount] = useState('');
  const [feePercentage, setFeePercentage] = useState('');
  const [data, setData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  // Detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    function formatNumber(value) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    }

    const calculatedCurrentFee = numberOfOrders * averageProductAmount * (feePercentage / 100) || 0;

    const predictedFees = [];
    for (let i = 1; i <= 5; i++) {
      const predictedFeePercentage = feePercentage - i * 0.1;
      const predictedFee = numberOfOrders * averageProductAmount * (predictedFeePercentage / 100) || 0;
      const calculateFlexmerceCharges = (numberOfOrders) => {
        let rate = 0; // Default rate when `numberOfOrders` is 0
        let lowTierPrice = t('lowTierPrice');
        let midTierPrice = t('midTierPrice');
        let topTierPrice = t('topTierPrice');

        // Function to remove currency symbols and convert to a number
        function parsePrice(priceString) {
          if (!priceString) return 0; // Handle undefined or null strings
          return parseFloat(priceString.replace(/[€£]/g, '').trim());
        }

        // Convert prices
        lowTierPrice = parsePrice(lowTierPrice);
        midTierPrice = parsePrice(midTierPrice);
        topTierPrice = parsePrice(topTierPrice);
        
        if (numberOfOrders > 0 && numberOfOrders <= 999) {
          rate = lowTierPrice;
        } else if (numberOfOrders >= 1000 && numberOfOrders <= 2000) {
          rate = midTierPrice;
        } else if (numberOfOrders > 2000) {
          rate = topTierPrice;
        }
        
        return numberOfOrders * rate;
      };

      const scenarioFlexmerceCharges = calculateFlexmerceCharges(numberOfOrders);
      const predictedSaving = calculatedCurrentFee - predictedFee - scenarioFlexmerceCharges;

      predictedFees.push({
        percentage: formatNumber(predictedFeePercentage) + '%',
        fee: formatNumber(predictedFee),
        saving: (predictedSaving >= 0 ? '+' : '') + formatNumber(predictedSaving),
        scenarioFlexmerce: formatNumber(scenarioFlexmerceCharges),
      });
    
    }

    // Structure data for a table or cards
    const tableData = [
      [t('calculateSavingsPage.feePercentage'), formatNumber(feePercentage) + '%', ...predictedFees.map((item) => item.percentage)],
      [t('calculateSavingsPage.feeAmount'), formatNumber(calculatedCurrentFee), ...predictedFees.map((item) => item.fee)],
      [t('calculateSavingsPage.feeAmountDifference'), '-', ...predictedFees.map((item) => '-' + formatNumber(calculatedCurrentFee - parseFloat(item.fee.replace(',', ''))))],
      [t('calculateSavingsPage.flexmerceCharges'), '-', ...predictedFees.map((item) => item.scenarioFlexmerce)],
      [t('calculateSavingsPage.totalSavings'), '-', ...predictedFees.map((item) => item.saving)],
    ];

    setData(tableData);
  }, [numberOfOrders, averageProductAmount, feePercentage]);

  const renderTable = () => (
    <div className="calculate-saving-table table-container calculate-saving-form mt-3">
      <table className="responsive-table">
        <thead>
          <tr className='calculate-saving-table-tr'>
            <th></th>
            <th>{t('calculateSavingsPage.currently')}</th>
            <th>{t('calculateSavingsPage.initialImpact')}<br /> <span style={{ fontSize: '0.6em' }}>{t('calculateSavingsPage.straightforwardToAchieve')}</span></th>
            <th>{t('calculateSavingsPage.enhancedReturns')}<br /> <span style={{ fontSize: '0.6em' }}>{t('calculateSavingsPage.promisingImprovement')}</span></th>
            <th style={{ backgroundColor: '#ff8a00', color: '#fff', fontSize: '1em' }}>{t('calculateSavingsPage.optimalBalance')}<br /> <span style={{ fontSize: '0.6em' }}>{t('calculateSavingsPage.thePopularResult')}</span> </th>
            <th>{t('calculateSavingsPage.advancedSavings')}<br /> <span style={{ fontSize: '0.6em' }}>{t('calculateSavingsPage.aimingAtHigherReturns')}</span></th>
            <th>{t('calculateSavingsPage.peakEfficiency')}<br /> <span style={{ fontSize: '0.6em' }}>{t('calculateSavingsPage.topEndPotential')}</span></th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              style={{
                backgroundColor: rowIndex === 4 ? '#ff8a00' : '',
                color: rowIndex === 0 ? '#ff8a00' : '#fff',
                fontSize: rowIndex === data.length - 1 ? '0.8rem' : '0.7rem',
                fontWeight: rowIndex === data.length - 1 ? 'bold' : 'normal',
              }}
              className='calculate-saving-table-tr'
            >
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    backgroundColor: cellIndex === 4 ? '#ff8a00' : '',
                    color: cellIndex === 0 ? '#ff8a00' : '#fff',
                    fontSize: (cellIndex === 0 || cellIndex === 4 || rowIndex === data.length - 1) ? '0.8rem' : '0.7rem',
                    fontWeight: cellIndex === 0 || rowIndex === 0 || rowIndex === data.length - 1 || cellIndex === 4 ? 'bold' : 'normal',
                  }}
                  className='calculate-saving-table-tr'
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderCards = () => {
    // Extract each scenario's data, skipping the first column header row (row[0])
    const scenarioData = data[0].slice(1).map((_, index) => {
      return data.map(row => row[index + 1]);
    });
  
    // Reorder the scenarios: Currently -> Scenario 3 -> others
    const reorderedScenarioData = [
      scenarioData[0],           // "Currently"
      scenarioData[3],           // "Scenario 3"
      ...scenarioData.slice(1, 3), // Rest of scenarios
      ...scenarioData.slice(4),   // remaining
    ];
  
    // Custom titles for each card
    const titles = [
      t('calculateSavingsPage.currently'),
      t('calculateSavingsPage.initialImpact') + " " + t('calculateSavingsPage.straightforwardToAchieve'),
      t('calculateSavingsPage.enhancedReturns') + " " + t('calculateSavingsPage.promisingImprovement'),
      t('calculateSavingsPage.optimalBalance') + " " + t('calculateSavingsPage.thePopularResult'),
      t('calculateSavingsPage.advancedSavings') + " " + t('calculateSavingsPage.aimingAtHigherReturns'),
      t('calculateSavingsPage.peakEfficiency') + " " + t('calculateSavingsPage.topEndPotential'),
    ];
  
    return (
      <div className="card-container mt-3">
        {reorderedScenarioData.map((scenario, index) => (
          <div 
            className="metric-card" 
            key={index}
            style={{
              backgroundColor: titles[index] === t('calculateSavingsPage.optimalBalance') + " " + t('calculateSavingsPage.thePopularResult') ? "#ff8a00" : "",
              color: titles[index] === t('calculateSavingsPage.optimalBalance') + " " + t('calculateSavingsPage.thePopularResult') ? "transparent" : "", 
            }}
          >
            <h4>{titles[index]}</h4>
            <div className="metric-values">
              {data.map((row, rowIndex) => (
                <div key={rowIndex} className="card-metric">
                  <h5>{row[0]}</h5>
                  <p>{scenario[rowIndex]}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <>
      <HomepageMenu />
      <Helmet>
        <title>{t('calculateSavings')}</title>
        <meta name="description" content={t('useFlexmerce')} />
      </Helmet>
      <div className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem" style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}>
        <div className="container mb-5">
          <h1 className='first-title-container font-orange text-center mt-5'>{t('calculateSavings')}</h1>
          <p className='mt-2 pt-3 mr-3 ml-3 font-light home-services-p  text-center'>
          {t('letsCalculate')}
          </p>
          <p className=' font-light home-services-p text-center' dangerouslySetInnerHTML={{ __html: t('designedToHelp') }}/>
          <div>
            <form className='calculate-saving-form mt-5'>
              <h3 className='font-light text-center mb-1'>{t('enterMonthlyFigures')}</h3>
              <hr className='mr-5 ml-5'></hr>
              <div className="row text-center">
                <div className="col-sm-4 col-12">
                  <div className="form-group">
                    <input
                      type="number"
                      value={numberOfOrders}
                      className="form-control p-4"
                      onChange={(e) => setNumberOfOrders(e.target.value)}
                      required
                      placeholder=""
                    />
                    <label className="floating-label">{t('numberOfOrders')}</label>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="form-group">
                    <input
                      type="number"
                      value={averageProductAmount}
                      step="0.01"
                      className="form-control p-4"
                      placeholder=""
                      onChange={(e) => setAverageProductAmount(e.target.value)}
                      required
                    />
                    <label className="floating-label">{t('avgProduct')}</label>
                  </div>
                </div>
                <div className="col-sm-4 col-12">
                  <div className="form-group">
                    <input
                      type="number"
                      value={feePercentage}
                      step="0.01"
                      className="form-control p-4"
                      placeholder=""
                      onChange={(e) => setFeePercentage(e.target.value)}
                      required
                    />
                    <label className="floating-label">{t('marketplaceFee')}</label>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {isMobile ? renderCards() : renderTable()}
          <p className='font-light home-services-p text-center mt-5'>
            {t('automaticallyDelivering')}
          </p>

          <p className='font-light home-services-p text-center'>
          {t('happyCustomer')}
          </p>

          <ContactSection />
        </div>
        <Footer />
      </div>
    </>
  );
}
