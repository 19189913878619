import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/services.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import firstImage from '../images/services.png'
import users from '../images/services-users.png'
import integrations from '../images/services-integrations.png'
import apis from '../images/services-apis.png'
import bmlogo from '../images/bm-logo.png'
import rflogo from '../images/rf-logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEuroSign, faListCheck, faScrewdriverWrench, faToolbox, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const { t } = useTranslation();
  const [menuHeight, setMenuHeight] = useState(0);
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  return (
    <>
    <HomepageMenu />
    <Helmet>
                <title>{t('services')}</title>
                <meta name="description" content={t('streamlineCustomer')} />

            </Helmet>
      <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
      <div className="container services-main-container">
        <div className='row landing-container'>
          
          <div className='col-sm-6 landing-container-image services-first-image-div'>
            <img src={firstImage} alt='autopilot' className='services-first-image' />
          </div>
          <div className='col-sm-6 landing-container-text'>
            <h1 className='first-title-container font-orange'>{t('autoPilot')}</h1>
            <p className='mt-4 font-light home-services-p'>
            {t('efficientlyStreamline')}
            </p>
            <a href='/registerBusiness'>
              <button className='btn-services-calculate-savings'>
              {t('freeTrialButton')}
              </button>
            </a>
            <a href='/calculatesavings'>
              <button className='btn-services-contact'>
              {t('predictSavingsButton')}
              </button>
            </a>
          </div>
        </div>

        <hr></hr>

        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>How We Help Reduce Fees and Automate Processes</h1>
            <p className=' font-light home-services-p'>
              {t('discoverHow')}
            </p>

          <div className='row mt-3'>
            <div className='col'></div>
            <div className='col-sm-3 marketplace-tile m-2'>
              <div className="services-icon">
                <img src={bmlogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy'/>
              </div>
              <a href="/backmarket"><h4 className='text-900 font-orange mb-4'>{t('bmInt')}</h4></a>
                <p className='home-services-p-1rem p-2'>{ t('ourBMInt')}</p>
                <ul>
                {Array.from({ length: 5 }, (_, index) => (
                  <li className='home-services-p-1rem' key={index}>
                  {t(`bmIntegration.${index}.description`)}
                  </li>
                ))}
              </ul>
            </div>
            <div className='col-sm-3 marketplace-tile m-2'>
            <div className="services-icon">
                <img src={rflogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy' />
              </div>
              <a href="/refurbed"><h4 className='text-900 font-orange mb-4'>{t('refurbedInt')}</h4></a>
              <p className='home-services-p-1rem p-2'>{t('ourRefurbed')}</p>
              <ul>
                {Array.from({ length: 5 }, (_, index) => (
                  <li className='home-services-p-1rem' key={index}>
                  {t(`refurbedIntegration.${index}.description`)}
                  </li>
                ))}
              </ul>
            </div>
            <div className='col'></div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/registerBusiness">
            <button className="btn-services-calculate-savings">{t('freeTrialButton')}</button>
          </a>
        </div>
        </div>

        <hr></hr>

        <div className='experience-container mt-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>{t('backedBy')}</h1>
          <p className='font-light home-services-p'>
          {t('saveThousands')}
          </p>
          <div className='row mt-5'>
            <div className='col-sm-4'>
              <img src={users} className='experience-images' alt='users'/>
              <p className='p-4font-light home-services-p'><span className='font-orange'>50+</span><br></br>{t('users')}</p>
            </div>
            <div className='col-sm-4'>
              <img src={integrations} className='experience-images' alt='integrations'/>
              <p className='p-4font-light home-services-p'><span className='font-orange'>200+</span><br></br>{t('integrations')}</p>
            </div>
            <div className='col-sm-4'>
              <img src={apis} className='experience-images' alt='APIs'/>
              <p className='p-4font-light home-services-p'><span className='font-orange'>50,000+</span><br></br>{t('tickets')}</p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }} className='mb-5'>
          <a href="/pricing">
            <button className="btn-services-calculate-savings">{t('explore')}</button>
          </a>
        </div>
        </div>

        <hr></hr>

        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h5 className='benefits-top-subtitle'>{t('saveBig')}</h5>
          <h1 className='mb-4 experience-title font-orange'>{t('keyBenefits')}</h1>
            <p className='font-light home-services-p'>
            {t('unlockPower')}
            </p>

          <div className='row mt-3'>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faEuroSign} alt='Euro Sign' />
              </div>
              <h4 className='text-900 font-orange mb-4'>{t('increaseProfit')}</h4>
              <p>{t('reduceFee')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faListCheck} alt='List Check' />
              </div>
              <h4 className='text-900 font-orange mb-4'>{t('automateProcess')}</h4>
              <p>{t('autoGenerate')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faClock} alt='Clock' />
              </div>
              <h4 className='text-900 font-orange mb-4'>{t('saveTime')}</h4>
              <p>{t('freeUp')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faScrewdriverWrench} alt='Troubleshooting' />
              </div>
              <h4 className='text-900 font-orange mb-4'>{t('customTroubleshooting')}</h4>
              <p>{t('escalateCustomer')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faToolbox}  alt='Maintenance' />
              </div>
              <h4 className='text-900 font-orange mb-4'>{t('ongoingMaintenance')}</h4>
              <p>{t('beyondRelease')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faPhoneVolume} alt='Customer service support' />
              </div>
                <h4 className='text-900 font-orange mb-4'>{t('dedicatedSupport')}</h4>
              <p>{t('undividedAttention')}</p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/appointment">
                <button className="btn-services-calculate-savings">{ t('bookADemo')}</button>
          </a>
        </div>
        </div>

        <ContactSection />
      </div>
      <Footer />
    </div>
    </>
  )
}