import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import CheckAuth from '../hooks/checkAuth';
import { FaBox } from "react-icons/fa";
import { BsFillBoxFill } from "react-icons/bs";
import { BsBoxSeamFill } from "react-icons/bs";
import 'bootstrap';
import '../css/dashboard.css';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import axiosInstance from '../components/axio';

import LineChart from '../components/LineChart';
import DoughnutChart from '../components/DoughnutChart';
import OnboardingModal from './onboarding';
import Confetti from "react-confetti";
import { useDispatch, useSelector } from 'react-redux';
import { setOnBoarded } from '../redux/slices/onboardSlice';
import { useTranslation } from 'react-i18next';

const DashboardTiles = ({ platformName, invoices, platformColor, icon, link, text }) => {
    return (
        <div className="tile" style={{ backgroundColor: platformColor }}>
            <div className="tile-platform">{platformName}</div>
            <div className="tile-invoices">{invoices}</div>
            <div className="tile-icon">{icon}</div>
            <div className="tile-link">{link}</div>
            <span className="tile-extra-info">{text}</span>
        </div>
    );
};

export default function Dashboard() {
    CheckAuth();
    const { t } = useTranslation();
    const isOnBoarded = useSelector((state) => state.onboard.isOnBoarded);
    const isIntegrationSetup = useSelector((state) => state.onIntegrationSlice.isIntegrationSetup);
    const isTemplateSetup = useSelector((state) => state.onInvoiceTemplateSlice.isTemplateSetup);
    const isProfileSetup = useSelector((state) => state.onProfile.isProfileSetup);
    const dispatch = useDispatch()
    const [celebration, setCelebration] = useState(false)

    const activate = () => {
        setCelebration(true)
        setTimeout(() => {
            setCelebration(false)
        }, 5000);
      };
      const updateIsOnboarded = async () => {
          try {
                const userId = localStorage.getItem('userId');
                const response = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL}/updateOnBoarded/${userId}`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                } 
            } catch (error) {
                console.error('Failed to set onboarded:', error);
            }
    };
    useEffect(() => {
        if (isIntegrationSetup && isTemplateSetup && isProfileSetup && !isOnBoarded) {
            dispatch(setOnBoarded(true));
            activate()
            updateIsOnboarded()
        }
    }, [isIntegrationSetup, isTemplateSetup, isProfileSetup])

    
    const navigate = useNavigate();
    const [numOfInvoices, setNumOfInvoices] = useState(0);
    const [loading, setLoading] = useState(false);
    const hasFetched = useRef(false);

    const [labels, setLabels] = useState([]);
    const [data, setData] = useState([]);

    const [platformCounts, setPlatformCounts] = useState({
        1: 0,
        2: 0,
        3: 0
    });

    async function FetchInvoices() {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`/invoices/${localStorage.getItem('userId')}`);
            if (response.status !== 200) {
                setLoading(false);
                throw new Error('Failed to fetch');
            }
            const data = await response.data;
    
            const invoicesByDay = data.reduce((acc, invoice) => {
                const creationDate = invoice?.basics?.creationDateTime;
                if (creationDate) {
                    const date = new Date(creationDate).toISOString().split('T')[0];
                    acc[date] = (acc[date] || 0) + 1;
                }
                return acc;
            }, {});
    
            const labels = [];
            const dataForChart = [];
            const now = new Date();
            const currentDay = now.getDate();

            for (let day = 1; day <= currentDay; day++) {
                const date = new Date(now.getFullYear(), now.getMonth(), day)
                    .toISOString()
                    .split('T')[0];
                labels.push(day.toString().padStart(2, '0'));
                dataForChart.push(invoicesByDay[date] || 0);
            }
    
            setNumOfInvoices(data.length);
            setLabels(labels);
            setData(dataForChart);
    
            const platformCounts = data.reduce((acc, invoice) => {
                const platform = invoice.basics.platform;
                acc[platform] = (acc[platform] || 0) + 1;
                return acc;
            }, {});
            setPlatformCounts(platformCounts);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    }    

    useEffect(() => {
        if (!hasFetched.current) {
            hasFetched.current = true;
            const fetchInvoices = async () => {
                FetchInvoices();
            };
            fetchInvoices();
        }
    }, []);

    const handleNewInvoiceClick = () => {
        navigate(`/invoice/new`);
    };

    const handleBackMarketFetchInvoices = async () => {
        setLoading(true);
        const controller = new AbortController();
        const timeout = setTimeout(() => {
            controller.abort();
        }, 1200000);
    
        try {
            const userId = localStorage.getItem('userId');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/backmarket/automateInvoicing/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: controller.signal,
            });
    
            clearTimeout(timeout);
    
            if (!response.ok && response.status !== 504) {
                setLoading(false);
                if (response.status === 404 || response.status === 500) {
                    alert('Failed to fetch invoices.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                setLoading(false);
                const result = await response.json();
                alert(result.message);
                FetchInvoices();
            }
        } catch (error) {
            setLoading(false);
    
            if (error.name === 'AbortError') {
                alert('Request timed out. Please try again.');
            } else {
                console.error('Failed to fetch invoices:', error);
                alert('Failed to fetch invoices. Please try again.');
            }
        }
    };

    const handleRefurbishedFetchInvoices = async () => {
        setLoading(true);
        const controller = new AbortController();
        const timeout = setTimeout(() => {
            controller.abort();
        }, 1200000);
        try {
            const userId = localStorage.getItem('userId');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/refurbished/automateInvoicing/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: controller.signal,
            });

            clearTimeout(timeout);

            if (!response.ok) {
                setLoading(false);
                if (response.status === 404 || response.status === 500) {
                    alert('Failed to fetch inovices.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                const result = await response.json();
                alert(result.message);
                FetchInvoices();
            }
        } catch (error) {
            setLoading(false);
            console.error('Failed to fetch inovices:', error);
            alert('Failed to fetch inovices. Please try again.');
        }
    };

    const platforms = [
        { name: 'Back Market', invoices: platformCounts[2], color: '#F5A623', icon: <FaBox />, text:  t('invoiceBM') },
        { name: 'Refurbed', invoices: platformCounts[3], color: '#4A90E2', icon: <BsBoxSeamFill />, text:  t('invoiceRefurbed') },
        { name: 'Manual', invoices: platformCounts[1], color: '#1b2033', icon: <BsFillBoxFill />, text: t('create') }
    ];

    const platformLabels = ['Back Market', 'Refurbed', 'Manual'];
    const platformData = [
        platformCounts[2] || 0,
        platformCounts[3] || 0,
        platformCounts[1] || 0,
    ];
    return (
        <div className='container'>
            <OnboardingModal isVisible={!isOnBoarded} />
            {celebration && (
                <Confetti width={window.innerWidth} height={window.innerHeight} />)}
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <h2 className='mt-3 mb-4 fw-bold'>{ t('welcome')} {localStorage.getItem('businessName')}</h2>
            <div className='bg-light text-dark text-center mb-2 messageBox'>
                <p className='p-3'>
                    {numOfInvoices > 0 ? (
                        <>{ t('created')} <b>{numOfInvoices}</b> { t('thisMonth')}</>
                    ) : (
                        <>{ t('havent')}</>
                    )}
                </p>
            </div>

            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}

            <div className='row mt-2 mb-4'>
                {platforms.map((platform, index) => (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-1 dashboard-tile" key={index}>
                        <button
                            key={index}
                            onClick={(e) => {
                                e.preventDefault();
                                if (platform.name === 'Back Market') {
                                    handleBackMarketFetchInvoices();
                                } else if (platform.name === 'Refurbed') {
                                    handleRefurbishedFetchInvoices();
                                } else if (platform.name === 'Manual') {
                                    handleNewInvoiceClick();
                                }
                            }}
                            style={{ 
                                textDecoration: 'none', 
                                background: 'none', 
                                border: 'none', 
                                cursor: 'pointer', 
                                width: '100%' // Adjust styling as needed 
                            }}
                        >
                            <DashboardTiles
                                platformName={platform.name}
                                invoices={platform.invoices}
                                platformColor={platform.color}
                                icon={platform.icon}
                                link={platform.link}
                                text={platform.text}
                            />
                        </button>
                    </div>    
                ))}
            </div>

            <div className='row mb-3 pb-5'>
                <div className='col-sm-8 mt-2'>
                    <div className="chart-container messageBox">
                        <h5 className='text-center mb-3'>{ t('perDay')}</h5>
                        <hr></hr>
                        <LineChart labels={labels} data={data} className='chart-graph' />
                    </div>
                </div>
                <div className='col-sm-4 mt-2'>
                    <div className="chart-container messageBox doughnut">
                        <h5 className="text-center mb-3">{ t('perPlatform')}</h5>
                        <hr></hr>
                        <DoughnutChart labels={platformLabels} data={platformData} />
                    </div>
                </div>
            </div>
        </div>
    );
}