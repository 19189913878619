import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/login.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import Footer from '../components/Footer';

export default function Privacy() {
    const [menuHeight, setMenuHeight] = useState(0);
    useEffect(() => {
        const menuElement = document.querySelector('.navbar');
        if (menuElement) {
        setMenuHeight(menuElement.offsetHeight);
        }
    }, []);

    return (
        <>
    <HomepageMenu />
    <Helmet>
      <title>Privacy policy</title>
      <meta name="description" content="Privacy policy"/>
    </Helmet>
    <div className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem" style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}>
      <div className="container mb-5">
          <div className='calculate-saving-form mt-5 text-justify'>
            <h2 className='text-900 font-orange'></h2>
            <div className='blog-content text-light'>
                <h1 className='font-orange text-center'>Privacy policy</h1>
                <p>
                Flexmerce ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy describes how we collect, use, disclose, and protect information when you visit our website and use our services ("Services"). By accessing or using our Services, you consent to the practices described in this Privacy Policy.                </p>
                <h3 className='font-orange'>1. Information We Collect</h3>
                <p>
                We may collect the following types of information:
                </p>
                <h5 className='font-orange'>a. Personal Information</h5>
                <p>Personal information refers to any data that identifies you as an individual, including but not limited to:</p>
                <ul>
                  <li>Contact Information: Name, email address, phone number, mailing address</li>
                  <li>Account Information: Username, password, and other login credentials</li>
                  <li>Billing Information: Payment details and billing address, processed through secure third-party services</li>
                </ul>
                <h5 className='font-orange'>b. Usage Data</h5>
                <p>We collect information about how you interact with our Services, which may include:</p>
                <ul>
                  <li>Log Information: IP address, browser type, access times, and pages viewed</li>
                  <li>Device Information: Device type, operating system, and device identifiers</li>
                  <li>Cookies and Tracking Technologies: We use cookies, beacons, and similar technologies to collect data about your browsing behavior on our website.</li>
                </ul>
                <h5 className='font-orange'>c. Information from Third Parties</h5>
                <p>We may collect information from third-party services integrated with Flexmerce, such as marketplaces and invoicing platforms, to provide seamless services to you.</p>

                <h3 className='font-orange'>2. How We Use Your Information</h3>
                <p>
                Flexmerce may use your information for the following purposes:
                </p>
                <ul>
                  <li>To Provide and Improve Services: Process transactions, fulfill requests, and enhance the performance of our Services.</li>
                  <li>To Communicate with You: Respond to inquiries, provide updates, send confirmations, and deliver important information.</li>
                  <li>For Marketing and Promotions: Inform you about new features, offers, and news related to Flexmerce (if you consent to receive such communications).</li>
                  <li>To Ensure Security: Protect your account, detect and prevent fraud, and ensure the security of our platform.</li>
                  <li>To Comply with Legal Obligations: Meet regulatory and compliance requirements.</li>
                </ul>

                <h3 className='font-orange'>3. How We Share Your Information</h3>
                <p>
                We do not sell your personal information. We may share your information with:
                </p>
                <ul>
                  <li>Service Providers: Third-party vendors who assist us in operating our business and delivering our Services, such as payment processors, data analytics providers, and cloud service providers.</li>
                  <li>Affiliates: Entities that are part of the Flexmerce family, to support and improve the Services.</li>
                  <li>Legal Obligations: Government authorities or regulatory bodies, as required to comply with legal processes or protect rights.</li>
                </ul>

                <h3 className='font-orange'>4. Cookies and Tracking Technologies</h3>
                <p>
                We use cookies and similar technologies to enhance your experience on our website. You may control the use of cookies through your browser settings, but disabling cookies may limit your ability to use certain features of our Services.
                </p>

                <h3 className='font-orange'>5. Security of Your Information</h3>
                <p>
                We use reasonable administrative, technical, and physical measures to protect your personal information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.
                </p>

                <h3 className='font-orange'>6. Your Rights and Choices</h3>
                <p>
                Depending on your jurisdiction, you may have rights regarding your personal information, such as:
                </p>
                <ul>
                  <li>Access and Correction: Request access to or correction of your personal data.</li>
                  <li>Data Portability: Request a copy of your personal data in a structured, machine-readable format.</li>
                  <li>Deletion: Request the deletion of your personal data under certain conditions.</li>
                  <li>Opt-Out of Marketing: Unsubscribe from marketing communications at any time by following the instructions in our emails or contacting us directly.</li>
                </ul>
                <p>
                For assistance with exercising these rights, please contact us at help@flexmerce.com.
                </p>

                <h3 className='font-orange'>7. Data Retention</h3>
                <p>
                We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. When information is no longer needed, we securely delete or anonymize it.
                </p>

                <h3 className='font-orange'>8. Third-Party Links</h3>
                <p>
                Our Services may include links to third-party websites or applications. We are not responsible for the privacy practices or content of these external sites. Please review the privacy policies of these third parties before interacting with their websites.
                </p>

                <h3 className='font-orange'>9. Children’s Privacy</h3>
                <p>
                Flexmerce does not knowingly collect or solicit personal information from children under the age of 13. If we learn that we have collected personal information from a child under 13 without verification of parental consent, we will promptly delete that information.
                </p>

                <h3 className='font-orange'>10. Changes to this Privacy Policy</h3>
                <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will post any updates on this page and, if significant, notify you via email or other communication channels. Your continued use of our Services after the effective date of the updated Privacy Policy constitutes acceptance of those changes.
                </p>

                <h3 className='font-orange'>11. Contact Us</h3>
                <p>
                If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us:
                </p>
                <ul>
                  <li>Email: help@flexmerce.com</li>
                  <li>Phone: +44 (0) 333 789 0702</li>
                </ul>
            </div>
          </div> 
        </div> 
        <Footer />
    </div>
    </>
    )
}