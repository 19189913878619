import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ContactSection() {
    const { t } = useTranslation();

    return (
        <>
        <div className='contact-section text-center mt-5 p-2 pb-4 pt-4'>
                <h1 className='text-light font-orange'>{t('startReducing')}</h1>
                <p className='text-light home-services-p'>{t('implementAndAutomate')}</p>
            <div className='row'>
                <div className='col-sm-5'>
                    <a href='/registerBusiness'>
                        <button className='btn-services-calculate-savings'>
                        {t('freeSevenDays')}
                        </button>
                    </a>
                </div>
                <div className='col-sm-3'>
                    <a href='/contact'>
                        <button className='btn-services-contact-2'>
                        {t('contactUs')}
                        </button>
                    </a>
                </div>
            </div>
        </div>
        </>
    );
}
