import { createSlice } from '@reduxjs/toolkit';

const getOnTaxFromStorage = () => {
  try {
    const storedValue = localStorage.getItem('isTaxSetup');
    return storedValue ? JSON.parse(storedValue) : false;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return false;
  }
};

const taxSlice = createSlice({
    name: 'isTaxSetup',
    initialState: {
      isTaxSetup: getOnTaxFromStorage(),
    },
    reducers: {
      setTax: (state, action) => {
        state.isTaxSetup = action.payload;
        localStorage.setItem('isTaxSetup', JSON.stringify(action.payload));
      },
      syncTax: (state) => {
        state.isTaxSetup = getOnTaxFromStorage();
      },
    },
  });
  
  export const { setTax, syncTax } = taxSlice.actions;
  export default taxSlice;
  