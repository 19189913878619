import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/services.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import firstImage from '../images/pricing.png'

import fivteen from '../images/topTier.png'
import seventeen from '../images/midTier.png'
import twenty from '../images/lowTier.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa7, faCircleCheck, faRepeat } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function Pricing() {
  const [menuHeight, setMenuHeight] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  return (
    <>
    <HomepageMenu />
    <Helmet>
      <title>{t('pricing')}</title>
      <meta name="description"
      content=
      "Explore Flexmerce's flexible and affordable pricing plans, designed to fit your invoicing needs. Start with a 7-day free trial and pay as low as 15p per invoice. Automate your invoicing process, reduce marketplace fees, and streamline your operations on platforms like Back Market and Refurbed. Discover how Flexmerce can help you save time and reduce costs, no matter the size of your business."
      />
    </Helmet>
      <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
      <div className="container services-main-container">
        <div className='row landing-container'>
          <div className='col-sm-6 landing-container-text'>
              <h1 className='first-title-container font-orange mt-3'>{ t('freeTrialButton').toUpperCase()}</h1>
              <p className='mt-4 font-light home-services-p' dangerouslySetInnerHTML={{ __html: t('experienceFlexmerce')}} />
            <a href='/calculatesavings'>
              <button className='btn-services-calculate-savings'>
                {t('landing.predictSavings')}
              </button>
            </a>
            <a href='/guide#flexmerce'>
              <button className='btn-services-contact'>
                {t('watchDemo')}
              </button>
            </a>
          </div>
          <div className='col-sm-6 landing-container-image pricing-first-image-div'>
            <img src={firstImage} alt='autopilot' className='pricing-first-image' />
          </div>
        </div>
        <hr></hr>
        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>{t('flexiblePricing')}</h1>
          <p className='font-light home-services-p'>
            {t('weUnderstand')}
          </p>

          <div className='row mt-3'>
            <div className='col-sm-3 services-tile m-2'>
              <img src={twenty} alt='backmarketLogo' className='marketplace-logo mb-3 mt-3' loading='lazy' />
              <h4 className='text-900 font-orange mb-4 mt-2'>1 - 999</h4>
              <h4 className='text-900 font-orange'>{t('lowTierPrice')}</h4>
              <p>{t('perAutomated')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <img src={seventeen} alt='backmarketLogo' className='marketplace-logo mb-3 mt-3' loading='lazy' />
              <h4 className='text-900 font-orange mb-4 mt-2'>1,000 - 2,000</h4>
              <h4 className='text-900 font-orange'>{t('midTierPrice')}</h4>
              <p>{t('perAutomated')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <img src={fivteen} alt='backmarketLogo' className='marketplace-logo mb-3 mt-3' loading='lazy' />
              <h4 className='text-900 font-orange mb-4 mt-2'>2,000+</h4>
              <h4 className='text-900 font-orange'>{t('topTierPrice')}</h4>
              <p>{t('perAutomated')}</p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/registerbusiness">
            <button className="btn-services-calculate-savings">{t('freeSevenDays')}</button>
          </a>
          </div>
        </div>

        <hr></hr>

        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>{t('how')}</h1>

          <div className='row mt-3'>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon mb-3">
                <FontAwesomeIcon icon={fa7} alt='number 7' />
              </div>
              <h4 className='text-900 font-orange mb-4'>{t('sevenDay')}</h4>
                <p>{t('experienceBenefit')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon mb-3">
                <FontAwesomeIcon icon={faCircleCheck} alt='circle check' />
              </div>
              <h4 className='text-900 font-orange mb-4'>{t('transparentPricing')}</h4>
              <p>{t('payForWhat')}</p>
            </div>
            <div className='col-sm-3 services-tile m-2'>
              <div className="services-icon mb-3">
                <FontAwesomeIcon icon={faRepeat} alt='repeat' />
              </div>
                <h4 className='text-900 font-orange mb-4'>{t('automatedInvoicing')}</h4>
              <p>{t('invoiceAutomated')}</p>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/services">
            <button className="btn-services-calculate-savings">{t('exploreServices')}</button>
          </a>
          </div>
        </div>

        <hr></hr>

        <div className="testimonial-section mt-3 mb-5">
          <h2 className="first-title-container font-orange text-center mt-5 mb-5">{t('whyChooseFM')}</h2>
            <div className="marketplace-tile services-why-choose-us-tile">
              <ul className='services-why-choose-us-ul'>
                <li
                 dangerouslySetInnerHTML={{__html : t('costEffective')}}/>
                <li dangerouslySetInnerHTML={{__html : t('efficient')}} />
                <li dangerouslySetInnerHTML={{__html : t('reducesFees')}} />
                <li dangerouslySetInnerHTML={{__html : t('seamlessIntegration')}} />
                <li dangerouslySetInnerHTML={{__html : t('secureReliable')}} />
              </ul>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <a href="/calculatesavings">
                <button className="btn-services-calculate-savings">{t('calculateYourSavings')}</button>
              </a>
            </div>
        </div>

        <hr></hr>

        <div className="faq-section">
          <h2 className="first-title-container font-orange text-center mt-5 mb-5">{t('faqsTitle')}</h2>
          <div className="faq-grid mb-5">
          {Array.from({ length: 4 }, (_, index) => (
              <div className="faq-flip-card" key={index}>
                <div className="faq-card-inner">
                  <div className="faq-card-front">
                  <h4 className="faq-question">
                    {t(`pricingFaqs.${index}.question`)}
                  </h4>
                  </div>
                  <div className="faq-card-back">
                  <p className="faq-answer">
                    {t(`pricingFaqs.${index}.answer`)}
                  </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div style={{ display: "flex", justifyContent: "center" }} className='mb-3'>
              <a href="/appointment">
                <button className="btn-services-calculate-savings">{t('bookADemo')}</button>
              </a>
            </div>
        </div>

        <ContactSection />
      </div>
      <Footer />
    </div>
    </>
  )
}