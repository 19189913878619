import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../css/login.css'
import '../css/calculateSavings.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import blogs from '../context/BlogsData.js';

export default function Blog() {
    const [menuHeight, setMenuHeight] = useState(0);
    useEffect(() => {
        const menuElement = document.querySelector('.navbar');
        if (menuElement) {
        setMenuHeight(menuElement.offsetHeight);
        }
    }, []);

    const { slug } = useParams();
    const blog = blogs.find((b) => b.slug === slug);

    return (
        <>
    <HomepageMenu />
    <Helmet>
      <title>{blog.title}</title>
      <meta name="description" content={blog.description}/>
    </Helmet>
    <div className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem" style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}>
      <div className="container mb-5">
          <div className='mt-5 text-center calculate-saving-form'>
            <h2 className='text-900 font-orange'>{blog.title}</h2>
            <div className='blog-content' dangerouslySetInnerHTML={{ __html: blog.content }} />
          </div>

          <ContactSection /> 
        </div> 
        <Footer />
    </div>
    </>
    )
}