import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/newInvoice.css';
import CheckAuth from '../hooks/checkAuth';
import { useNavigate, useParams  } from 'react-router-dom';
import axiosInstance from '../components/axio';
import { Circles } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

export default function NewInvoice() {
    CheckAuth();
    const { t } = useTranslation();
    const { invoiceId } = useParams();
    const navigate = useNavigate();
    const isEditMode = Boolean(invoiceId);
    const [loading, setLoading] = useState(false);
    const getCurrentDateTime = () => {
        const now = new Date();
        const timezoneOffset = now.getTimezoneOffset() * 60000;
        const localISOTime = new Date(now - timezoneOffset).toISOString().slice(0, 16);
        return localISOTime;
    };

    const [rows, setRows] = useState([{
        product: '',
        description: '',
        quantity: '',
        unitPrice: '',
        totalPrice: ''
      }]);

    const [invoiceDetails, setInvoiceDetails] = useState({
        shippingCost: '',
        discount: '',
        subTotal: '',
        paidAmount: '',
        balance: '',
        shippingAddress: '',
        shipper: '',
        trackingNumber: '',
        orderRef: '',
        invoiceDateTime: isEditMode ? '' : getCurrentDateTime(),
        currency: '',
        customerName: '',
        companyName: '',
        phoneNumber: '',
        billingAddress: '',
        companyRegNumber: '',
        taxPercentage: '',
        selectedTaxOptionId: '' 
    });

    const [taxOptions, setTaxOptions] = useState([]); 

    useEffect(() => {
        async function fetchTaxOptions() {
            const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
            if (userId) {
                setLoading(true);
                try {
                    const response = await axiosInstance.get(`/taxOption/${userId}`);
                    if (response.status !== 200) {
                        throw new Error('Failed to fetch Tax options');
                    }
                    const data = await response.data;
                    setTaxOptions(Array.isArray(data) ? data : []);
                    setLoading(false);
                } catch (error) {
                    console.error('Error:', error);
                    setLoading(false);
                }
            }
        }
        fetchTaxOptions();
    }, []);

    useEffect(() => {
        if (isEditMode) {
            setRows([{ product: '', description: '', quantity: '', unitPrice: '', totalPrice: '' }]);
            setInvoiceDetails({
                shippingCost: '',
                discount: '',
                subTotal: '',
                paidAmount: '',
                balance: '',
                shippingAddress: '',
                shipper: '',
                trackingNumber: '',
                orderRef: '',
                invoiceDateTime: '',
                currency: '',
                customerName: '',
                companyName: '',
                phoneNumber: '',
                billingAddress: '',
                companyRegNumber: '',
                taxPercentage: ''
            });
        }
    }, [invoiceId, isEditMode]);

    const getLocalDateTime = (utcDate) => {
        const date = new Date(utcDate);
        const timezoneOffset = date.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
        const localDate = new Date(date.getTime() - timezoneOffset); // Adjust to local time
        return localDate.toISOString().slice(0, 16); // Format it as yyyy-MM-ddTHH:mm
    };

    useEffect(() => {
        const fetchInvoiceData = async () => {
            if (invoiceId) {
                setLoading(true);
                try {
                    const response = await axiosInstance.get(`/invoices/getSingleInvoice/${invoiceId}`);
                    if (response.status !== 200) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    const data = await response.data;
                    const invoiceData = data.invoice;
                    const formattedDateTime = getLocalDateTime(invoiceData.basics.invoiceDateTime);
                    const formattedCreationDateTime = getLocalDateTime(invoiceData.basics.creationDateTime);


                    setInvoiceDetails({
                        invoiceNo: invoiceData.basics.invoiceNo,
                        orderRef: invoiceData.basics.orderRef,
                        platform: invoiceData.basics.platform,
                        invoiceDateTime: formattedDateTime,
                        creationDateTime: formattedCreationDateTime,
                        currency: invoiceData.basics.currency,
                        customerName: invoiceData.customer.name,
                        companyName: invoiceData.customer.companyName,
                        vatNumber: invoiceData.customer.vatNumber,
                        phoneNumber: invoiceData.customer.phone,
                        emailAddress: invoiceData.customer.email,
                        billingAddress: invoiceData.customer.billingAddress,
                        shippingCost: invoiceData.shippingDetails.cost ? invoiceData.shippingDetails.cost.toString() : '',
                        shippingAddress: invoiceData.shippingDetails.address,
                        shipper: invoiceData.shippingDetails.shipper,
                        trackingNumber: invoiceData.shippingDetails.trackingNumber,
                        taxPercentage: invoiceData.financials.taxPercentage ? invoiceData.financials.taxPercentage.toString() : '',
                        selectedTaxOptionId: invoiceData.financials.taxOptionId || '',
                        paidAmount: invoiceData.financials.paidAmount ? invoiceData.financials.paidAmount.toString() : '',
                        discount: invoiceData.financials.discount ? invoiceData.financials.discount.toString() : '',
                        companyRegNumber: invoiceData.customer.companyRegNumber
                    });
                    
                    setRows(invoiceData.products.map(prod => ({
                        product: prod.name,
                        description: prod.description,
                        quantity: prod.quantity ? prod.quantity.toString() : '0',
                        unitPrice: prod.unitPrice ? prod.unitPrice.toString() : '0.00',
                        totalPrice: prod.quantity && prod.unitPrice ? (prod.quantity * prod.unitPrice).toFixed(2) : '0.00'
                    })));
                    setLoading(false);
                } catch (error) {
                    console.error("Failed to fetch invoice:", error);
                    setLoading(false);
                    alert("Failed to load invoice details.");
                }
            }
        };
    
        fetchInvoiceData();
    }, [invoiceId]); 

    const handleChange = (index, field, value) => {
        setRows(prevRows => {
            const newRows = [...prevRows];
            const modifiedRow = {...newRows[index], [field]: value};

            if (field === 'quantity' || field === 'unitPrice') {
                const quantity = parseInt(modifiedRow['quantity']) || 0;
                const unitPrice = parseFloat(modifiedRow['unitPrice']) || 0.0;
                modifiedRow['totalPrice'] = (quantity * unitPrice).toFixed(2);
            }

            newRows[index] = modifiedRow;
            return newRows;
        });
    };

    const handleAddRow = () => {
        const newRow = { product: '', description: '', quantity: '', unitPrice: '', totalPrice: '' };
        setRows([...rows, newRow]);
      };
    
      const handleRemoveRow = index => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
      };

    const submitBttonVal = isEditMode ? t('ManageInvoicePages.editInvoice') : t('ManageInvoicePages.createInvoice');

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();

        const selectedTaxOption = taxOptions.find(option => option._id === invoiceDetails.selectedTaxOptionId);

        // Calculate the taxAmount
        const subTotal = parseFloat(invoiceDetails.subTotal || 0);
        const taxPercentage = parseFloat(invoiceDetails.taxPercentage || 0);
        const taxAmount = ((taxPercentage / 100) * subTotal).toFixed(2);
        
        // Calculate balance (total - paidAmount)
        const totalAmount = (subTotal + parseFloat(taxAmount || 0) + parseFloat(invoiceDetails.shippingCost || 0)).toFixed(2);
        const balance = (totalAmount - parseFloat(invoiceDetails.paidAmount || 0)).toFixed(2);

        let creationDateTime = isEditMode ? new Date(invoiceDetails.creationDateTime) : getCurrentDateTime();

        const invoicePayload = {
            userId: localStorage.getItem('userId'),
            basics: {
                invoiceNo: invoiceDetails.invoiceNo || "",
                creationDateTime: creationDateTime,
                invoiceDateTime: new Date(invoiceDetails.invoiceDateTime),
                orderRef: invoiceDetails.orderRef,
                platform: invoiceDetails.platform,
                businessID: localStorage.getItem('userId'),
                currency: invoiceDetails.currency,
                emailSent: false
            },
            customer: {
                name: invoiceDetails.customerName,
                companyName: invoiceDetails.companyName,
                vatNumber: invoiceDetails.vatNumber,
                phone: invoiceDetails.phoneNumber,
                email: invoiceDetails.emailAddress, 
                billingAddress: invoiceDetails.billingAddress,
                companyRegNumber: invoiceDetails.companyRegNumber
            },
            products: rows.map(row => ({
                name: row.product,
                description: row.description,
                quantity: parseInt(row.quantity, 10),
                unitPrice: parseFloat(row.unitPrice)
            })),
            shippingDetails: {
                cost: parseFloat(invoiceDetails.shippingCost),
                address: invoiceDetails.shippingAddress,
                shipper: invoiceDetails.shipper,
                trackingNumber: invoiceDetails.trackingNumber
            },
            financials: {
                invoiceName: selectedTaxOption ? selectedTaxOption.invoiceName : "",
                invoiceNote: selectedTaxOption ? selectedTaxOption.invoiceNote : "",
                taxOptionId: invoiceDetails.selectedTaxOptionId,
                taxPercentage: parseFloat(invoiceDetails.taxPercentage),
                taxAmount: parseFloat(taxAmount),
                discount: parseFloat(invoiceDetails.discount),
                totalAmount: parseFloat(totalAmount),
                paidAmount: parseFloat(invoiceDetails.paidAmount),
                balance: parseFloat(balance)
            }
        };
            const url = invoiceId ? `/editInvoice/${invoiceId}` : `/invoices`;
            try {
                const response = invoiceId ? await axiosInstance.put(url, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(invoicePayload)
                }) : await axiosInstance.post(url, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(invoicePayload)
                });
                if (response.status !== 200 && response.status !== 201) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                await response.data;
                setLoading(false);
                navigate('/invoices');
            } catch (error) {
                console.error('Failed to create/update invoice:', error);
                setLoading(false);
                alert('Failed to create/update invoice.');
            }
    };

    const handleInvoiceDetailsChange = (field, value) => {
        setInvoiceDetails(prevDetails => ({
            ...prevDetails,
            [field]: value
        }));
    };

    useEffect(() => {
        const totalProductsCost = rows.reduce((sum, row) => sum + parseFloat(row.totalPrice || 0), 0);
        const shippingCost = parseFloat(invoiceDetails.shippingCost || 0);
        const discount = parseFloat(invoiceDetails.discount || 0);
        const paidAmount = parseFloat(invoiceDetails.paidAmount || 0);
    
        const subTotalCalculated = totalProductsCost - discount;
        const subTotalAndShippingCost = subTotalCalculated + shippingCost;
    
        const taxPercentage = parseFloat(invoiceDetails.taxPercentage || 0);
        const taxAmount = (subTotalCalculated * taxPercentage) / 100;
    
        const balance = (subTotalAndShippingCost + taxAmount - paidAmount).toFixed(2);
        const subTotal = subTotalCalculated.toFixed(2);
    
        setInvoiceDetails(prevDetails => ({
            ...prevDetails,
            subTotal,
            balance,
            taxAmount: taxAmount.toFixed(2)
        }));
    }, [rows, invoiceDetails.shippingCost, invoiceDetails.discount, invoiceDetails.paidAmount, invoiceDetails.taxPercentage]);
    

    return (
        <>
        <Helmet>
                <title>Invoice</title>
            </Helmet>
            <div className='m-4'>
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
            <h2 className='m-3 fw-bold'>{isEditMode ? t('ManageInvoicePages.editInvoice') : t('ManageInvoicePages.newInvoice')}</h2>
                <form onSubmit={handleSubmit}>
                    <table className='table white-background table-custom-radius'>
                        <tbody>
                            <tr>
                                <td>{t('ManageInvoicePages.invoiceDate')}</td>
                                <td>
                                    <input type='datetime-local' className='form-control' name='invoiceDateTime'
                                        value={invoiceDetails.invoiceDateTime}
                                        onChange={(e) => handleInvoiceDetailsChange('invoiceDateTime', e.target.value)}
                                        required/>
                                </td>
                                <td>{t('ManageInvoicePages.platform')}</td>
                                <td>
                                    <select className='form-control' name='platform' value={invoiceDetails.platform}
                                    onChange={(e) => handleInvoiceDetailsChange('platform', e.target.value)}
                                    required>
                                        <option value={''}> --- {t('ManageInvoicePages.selectPlatform')} --- </option>
                                        <option value={'1'}>Manual</option>
                                        <option value={'2'}>Back Market</option>
                                        <option value={'3'}>Refurbed</option>
                                    </select>
                                </td>
                                <td>{t('ManageInvoicePages.OrderRef')}</td>
                                <td>
                                    <input type='text' className='form-control' name='orderRef'
                                        value={invoiceDetails.orderRef}
                                        onChange={(e) => handleInvoiceDetailsChange('orderRef', e.target.value)}
                                        placeholder={t('ManageInvoicePages.OrderRef')}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('ManageInvoicePages.customerName')}</td>
                                <td>

                                        <input type="text"
                                        className='form-control'
                                        name="customerName"
                                        value={invoiceDetails.customerName || ""}
                                        onChange={(e) => handleInvoiceDetailsChange('customerName', e.target.value)}
                                        placeholder={t('ManageInvoicePages.customerName')} required />

                                </td>
                                <td>{t('ManageInvoicePages.phoneNumber')}</td>
                                <td>
                                    <input type='text' className='form-control' name='phoneNumber'
                                        value={invoiceDetails.phoneNumber}
                                        onChange={(e) => handleInvoiceDetailsChange('phoneNumber', e.target.value)}
                                        placeholder={t('ManageInvoicePages.phoneNumber')}/>
                                </td>
                                <td>{t('ManageInvoicePages.emailAddress')}</td>
                                <td>
                                    <input type='email' className='form-control' name='emailAddress'
                                        value={invoiceDetails.emailAddress}
                                        onChange={(e) => handleInvoiceDetailsChange('emailAddress', e.target.value)}
                                        placeholder={t('ManageInvoicePages.emailAddress')}/>
                                </td>
                                
                            </tr>
                            <tr>
                                <td>{t('ManageInvoicePages.companyName')}</td>
                                <td>
                                    <input type='text' className='form-control' name='companyName'
                                        value={invoiceDetails.companyName}
                                        onChange={(e) => handleInvoiceDetailsChange('companyName', e.target.value)}
                                        placeholder={t('ManageInvoicePages.companyName')}/>
                                </td>
                                <td>{t('ManageInvoicePages.companyRegNumber')}</td>
                                <td>
                                    <input type='text' className='form-control' name='companyRegNumber'
                                        value={invoiceDetails.companyRegNumber}
                                        onChange={(e) => handleInvoiceDetailsChange('companyRegNumber', e.target.value)}
                                        placeholder={t('ManageInvoicePages.companyRegNumber')}/>
                                </td>
                                <td>{t('ManageInvoicePages.vatNumber')}</td>
                                <td>
                                    <input type='text' className='form-control' name='vatNumber'
                                        value={invoiceDetails.vatNumber}
                                        onChange={(e) => handleInvoiceDetailsChange('vatNumber', e.target.value)}
                                        placeholder={t('ManageInvoicePages.vatNumber')}/>
                                </td>
                                
                            </tr>
                            <tr>
                                <td>{t('ManageInvoicePages.currency')}</td>
                                <td>
                                    <select className='form-control' name='currency' value={invoiceDetails.currency}
                                    onChange={(e) => handleInvoiceDetailsChange('currency', e.target.value)}
                                    required>
                                        <option value=''> --- {t('ManageInvoicePages.selectCurrency')} --- </option>
                                        <option value={'EUR'}>EUR</option>
                                        <option value={'GBP'}>GBP</option>
                                        <option value={'USD'}>USD</option>
                                        <option value={'SEK'}>SEK</option>
                                        <option value={'DKK'}>DKK</option>
                                    </select>
                                </td>
                                <td>{t('ManageInvoicePages.billingAddress')}</td>
                                <td colSpan={3}>
                                    <input type='text' className='form-control' name='billingAddress'
                                        value={invoiceDetails.billingAddress}
                                        onChange={(e) => handleInvoiceDetailsChange('billingAddress', e.target.value)}
                                        placeholder={t('ManageInvoicePages.billingAddress')} required/>
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <table className='table white-background table-custom-radius'>
                        <tbody>
                            <tr className='text-center'>
                                <th style={{width:'30%'}}>{t('ManageInvoicePages.product')}</th>
                                <th style={{width:'25%'}}>{t('ManageInvoicePages.description')}</th>
                                <th style={{width:'10%'}}>{t('ManageInvoicePages.qty')}</th>
                                <th style={{width:'10%'}}>{t('ManageInvoicePages.unitPrice')}</th>
                                <th style={{width:'10%'}}>{t('ManageInvoicePages.totalPrice')}</th>
                                <th style={{width:'5%'}}></th>
                            </tr>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <td><input type='text' className='form-control' value={row.product} onChange={e => handleChange(index, 'product', e.target.value)} placeholder={t('ManageInvoicePages.productName')}/></td>
                                    <td><input type='text' className='form-control' value={row.description} onChange={e => handleChange(index, 'description', e.target.value)} placeholder=''/></td>
                                    <td><input type='number' className='form-control' value={row.quantity} onChange={e => handleChange(index, 'quantity', e.target.value)} placeholder={t('ManageInvoicePages.qty')}/></td>
                                    <td><input type='number' step="0.01" className='form-control' value={row.unitPrice} onChange={e => handleChange(index, 'unitPrice', e.target.value)} placeholder={t('ManageInvoicePages.unitPrice')}/></td>
                                    <td><input type='number' step="0.01" className='form-control' value={row.totalPrice} readOnly placeholder={t('ManageInvoicePages.totalPrice')}/></td>
                                    <td><button type='button' className='btn btn-danger' onClick={() => handleRemoveRow(index)}>X</button></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <button type='button' className="btn btn-outline-secondary p-2 text-dark rounded-md" onClick={handleAddRow}>{t('ManageInvoicePages.addNewItem')}</button>
                                </td>
                                <td colSpan={5}></td>
                            </tr>
                        </tfoot>
                    </table>

                    <table className='table white-background table-custom-radius'>
                        <tbody>
                            <tr>
                                <td>{t('ManageInvoicePages.shippingAddress')}</td>
                                <td colSpan={3}>
                                    <input type='text' className='form-control'
                                        name='shippingAddress' value={invoiceDetails.shippingAddress}
                                        onChange={(e) => setInvoiceDetails(prev => ({ ...prev, shippingAddress: e.target.value }))}
                                        placeholder={t('ManageInvoicePages.shippingAddress')}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('ManageInvoicePages.shipper')}</td>
                                <td>
                                    <input type='text' className='form-control'
                                        name='shipper' value={invoiceDetails.shipper}
                                        onChange={(e) => setInvoiceDetails(prev => ({ ...prev, shipper: e.target.value }))}
                                        placeholder={t('ManageInvoicePages.shipper')}/>
                                </td>
                                <td>{t('ManageInvoicePages.trackingNumber')}</td>
                                <td>
                                    <input type='text' className='form-control'
                                        name='trackingNumber' value={invoiceDetails.trackingNumber}
                                        onChange={(e) => setInvoiceDetails(prev => ({ ...prev, trackingNumber: e.target.value }))}
                                        placeholder={t('ManageInvoicePages.trackingNumber')}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('ManageInvoicePages.discount')}</td>
                                <td>
                                    <input type='number' step="0.01" className='form-control'
                                        name='discount' value={invoiceDetails.discount}
                                        onChange={(e) => setInvoiceDetails(prev => ({ ...prev, discount: e.target.value }))}
                                        placeholder={t('ManageInvoicePages.discount')}/>
                                </td>
                                <td>{t('ManageInvoicePages.subTotal')}</td>
                                <td>
                                    <input type='number' step="0.01" className='form-control'
                                        name='subTotal' value={invoiceDetails.subTotal} readOnly
                                        placeholder={t('ManageInvoicePages.subTotal')}/>
                                </td>
                            </tr>
                            <tr>
                                <td>{t('ManageInvoicePages.taxType')}</td>
                                <td>
                                    
                                <select
                                    className="form-control"
                                    name="taxOption"
                                    value={invoiceDetails.selectedTaxOptionId}  // This will store the selected option's _id
                                    onChange={(e) => {
                                        const selectedOption = e.target.options[e.target.selectedIndex];
                                        const taxPercentage = selectedOption.getAttribute('data-percentage'); // Get data-percentage
                                        const selectedTaxOptionId = e.target.value; // Get the _id value
                                        setInvoiceDetails((prev) => ({
                                            ...prev,
                                            taxPercentage, // Store the percentage value directly
                                            selectedTaxOptionId // Store the selected option's _id
                                        }));
                                    }}
                                    required
                                >
                                    <option value=""> --- {t('ManageInvoicePages.selectTaxOption')} --- </option>
                                    {taxOptions
                                        .filter(taxOption => taxOption.active === 1)  // Filter out inactive tax options
                                        .map((taxOption, index) => (
                                            <option
                                                key={index}
                                                value={taxOption._id}  // Set the value to the _id so the select component knows which is selected
                                                data-percentage={taxOption.percentage} // Attach the data-percentage attribute
                                            >
                                                {taxOption.optionTitle}
                                            </option>
                                        ))}
                                </select>


  
                                </td>
                                <td>{t('ManageInvoicePages.taxAmount')}</td>
                                <td>
                                    <input type='number' step="0.01" className='form-control'
                                        name='taxAmount' value={invoiceDetails.taxAmount}
                                        placeholder={t('ManageInvoicePages.taxAmount')} readOnly/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td>{t('ManageInvoicePages.shippingCost')}</td>
                                <td>
                                    <input type='number' step="0.01" className='form-control'
                                        name='shippingCost' value={invoiceDetails.shippingCost}
                                        onChange={(e) => setInvoiceDetails(prev => ({ ...prev, shippingCost: e.target.value }))}
                                        placeholder={t('ManageInvoicePages.shippingCost')}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td>{t('ManageInvoicePages.paidAmount')}</td>
                                <td>
                                    <input type='number' step="0.01" className='form-control'
                                        name='paidAmount' value={invoiceDetails.paidAmount}
                                        onChange={(e) => setInvoiceDetails(prev => ({ ...prev, paidAmount: e.target.value }))}
                                        placeholder={t('ManageInvoicePages.paidAmount')}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}></td>
                                <td>{t('ManageInvoicePages.balance')}</td>
                                <td>
                                    <input type='number' step="0.01" className='form-control'
                                        name='balance' value={invoiceDetails.balance} readOnly
                                        placeholder={t('ManageInvoicePages.balance')}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <input type="submit" className='btn btn-dark w-25 p-2 mt-3 mb-3 rounded-md  bg-blue' value={submitBttonVal}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>


        </>
    )
}