import React, { useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Dashboard from './pages/Dashboard';
import NewInvoice from './pages/NewInvoice';
import Customers from './pages/Customers';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import PrintInvoice from './pages/PrintInvoice';
import Logout from './pages/logout';
import NoPage from './pages/NoPage';
import ResetPassword from './pages/ResetPassword';
import RegisterBusiness from './pages/RegisterBusiness';
import AdminLogin from './pages/AdminLogin';
import AddUpdateTaxOption from './pages/AddUpdateTaxOption';
import ManageTaxOptions from './pages/ManageTaxOptions';
import ExportInvoices from './pages/ExportInvoices';
import PrintMultipleInvoices from './pages/PrintMultipleInvoices';
import Services from './pages/Services';
import Appointment from './pages/Appointment';
import CalculateSavings from './pages/CalculateSavings';
import JobHistories from './pages/JobHistories';
import Pricing from './pages/Pricing';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import SendCustomEmail from './pages/SendCustomEmail';
import EmailVerification from './pages/EmailVerification';
import Invoices from './pages/Invoices';
import Guide from './pages/Guide';
import BackMarket from './pages/BackMarket';
import Refurbed from './pages/Refurbed';

import { AuthProvider, useAuth } from './context/AuthContext';
import Blog from './pages/Blog'; // Your dynamic blog page component
import BlogList from './pages/BlogList';
import store from './redux/store/store';
import { Provider, useSelector } from 'react-redux';
import BusinessDetails from './pages/BusinessDetails';
import InvoiceTemplate from './pages/InvoiceTemplate';
import Integrations from './pages/Integrations';
import { useDispatch } from 'react-redux';
import { syncProfileSetup } from './redux/slices/profileSlice';
import { syncTemplate } from './redux/slices/invoiceTemplateSlice';
import { syncIntegration } from './redux/slices/integrationSlice';
import { syncTax } from './redux/slices/taxSlice';
import { syncOnBoarded } from './redux/slices/onboardSlice';

const AppContent = () => {
  const location = useLocation(); 
  const { isAuthenticated } = useAuth(); // Now inside the context of AuthProvider
  const isOnBoarded = useSelector((state) => state.onboard.isOnBoarded);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'isProfileSetup') {
        dispatch(syncProfileSetup());
      } else if (event.key === 'isTemplateSetup') {
        dispatch(syncTemplate());
      } else if (event.key === 'isIntegrationSetup') {
        dispatch(syncIntegration());
      } else if (event.key === 'isTaxSetup') {
        dispatch(syncTax());
      } else if (event.key === 'isOnBoarded') {
        dispatch(syncOnBoarded());
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  const navbarRoutes = [
    '/dashboard',
    '/reports',
    '/settings',
    '/customers',
    '/invoices',
    '/invoice/new',
    '/invoice/edit/:invoiceId',
    '/exportInvoices',
    '/jobHistories',
    '/manageTaxOptions',
    '/addUpdateTaxOption/new',
    '/addUpdateTaxOption/edit/:taxOptionId',
    '/settings/businessDetails',
    '/settings/invoiceTemplate',
    '/settings/integrations'
  ];

  const showNavbar = isAuthenticated && navbarRoutes.some((route) => {
    const regex = new RegExp(`^${route.replace(':invoiceId', '[a-zA-Z0-9]+').replace(':taxOptionId', '[a-zA-Z0-9]+')}$`);
    return regex.test(location.pathname);
  });

  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route 
          path="/login" 
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" replace state={{ from: location }} />
            ) : (
              <Login />
            )
          } 
        />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/invoice/new" element={<NewInvoice />} />
          <Route path="/invoice/edit/:invoiceId" element={<NewInvoice />} /> 
          <Route path="/customers" element={<Customers />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/printInvoice/:invoiceId" element={<PrintInvoice />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/verification/:token" element={<ResetPassword />} />
          <Route 
          path="/registerbusiness" 
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" replace state={{ from: location }} />
            ) : (
              <RegisterBusiness />
            )
          } 
        />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/manageTaxOptions" element={<ManageTaxOptions />} />
          <Route path="/addUpdateTaxOption/new" element={<AddUpdateTaxOption />} />
          <Route path="/addUpdateTaxOption/edit/:taxOptionId" element={<AddUpdateTaxOption />} />
          <Route path="/exportInvoices" element={<ExportInvoices />} />
          <Route path="/printMultipleInvoices" element={<PrintMultipleInvoices />} />
          <Route path="/services" element={<Services />} />
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/calculateSavings" element={<CalculateSavings />} />
          <Route path="/jobHistories" element={<JobHistories />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/verification" element={<EmailVerification />} />
          <Route path="/sendCustomEmail" element={<SendCustomEmail />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/settings/businessDetails" element={<BusinessDetails />} />
          <Route path="/settings/invoiceTemplate" element={<InvoiceTemplate />} />
          <Route path="/settings/integrations" element={<Integrations />} />
          <Route path="/backmarket" element={<BackMarket />} />
          <Route path="/refurbed" element={<Refurbed />} />

          <Route path="/blogs" element={<BlogList />} />
          <Route path="/blogs/:slug" element={<Blog />} /> {/* Dynamic blog route */}
          <Route path="*" element={<NoPage />} />
        </Routes>
        </>
  );
};

const App = () => {
  return (
    <AuthProvider> {/* Wrap the entire app in AuthProvider */}
      <Provider store={store}>
      <Router> 
          <AppContent /> {/* Content component now uses useAuth correctly */}
      </Router>
      </Provider>
    </AuthProvider>
  );
};

export default App;