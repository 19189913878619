import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap'
import '../css/dashboard.css';
import CheckAuth from '../hooks/checkAuth';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../components/axio';
import { Circles } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

export default function Settings() {
    CheckAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const hasFetched = useRef(false);
    const [businessDetails, setBusinessDetails] = useState({
        basics: {
            businessName: '',
            contactName: '',
            contactNumber: '',
            contactAddress: '',
            contactEmail: '',
            userId: userId
        },
        invoiceTemplateDetails: {
            companyName: '',
            contactNumber: '',
            emailAddress: '',
            regNumber: '',
            vatNumber: '',
            companyAddress: ''
        },
        integrations: {
            backMarket: '',
            refurbed: '',
        }
    });

    // Example function to handle input changes for deeply nested data
    const handleInputChange = (section, key, value) => {

        setBusinessDetails(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [key]: value
            }
        }));
    };

    // Example of fetching business details
    useEffect(() => {
        if (!hasFetched.current) {
            // Run only once
            hasFetched.current = true;
            async function fetchBusinessDetails() {
                setLoading(true);
                if (userId) {
                    try {
                        const response = await axiosInstance.get(`/businesses/${userId}`);
                        if (response.status !== 200) {
                            throw new Error('Failed to fetch business data');
                        }
                        const data = await response.data;
                        setBusinessDetails(data);
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        console.error('Error:', error);
                    }
                }
            }
            fetchBusinessDetails();
        }
    }, [userId]);

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        try {
            const response = await axiosInstance.put(`/businesses/${userId}`, {
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(businessDetails)
            });
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setLoading(false);
            alert('Settings updated successfully!');
        } catch (error) {
            setLoading(false);
            console.error('Failed to update settings:', error);
            alert('Failed to update settings.');
        }
    };

    function handleTaxBtnClick() {
        navigate(`/manageTaxOptions`);
    }

    return (
        <div className='container mb-5'>
            <Helmet>
                <title>{ t('SettingsPage.settings')}</title>
            </Helmet>
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
            <h2 className='mt-3 mb-4 fw-bold'>{ t('SettingsPage.settings')}</h2>
            <form onSubmit={handleSubmit}>
            <div className='card border-radius-20'>
                <h4 className='text-center mt-3'>{ t('SettingsPage.businessDetails')}</h4>
                <hr className='mr-5 ml-5'></hr>

                <div className='row'>
                    <div className='col-sm-6'>
                        <div className="form-group m-1 p-1">
                        <input
                            type="text"
                            value={businessDetails.basics.businessName}
                            onChange={e => handleInputChange('basics', 'businessName', e.target.value)}
                            placeholder=""
                            className='form-control w-100'
                            required
                        />
                        <label className="floating-label-register">{ t('SettingsPage.businessName')}</label>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className="form-group m-1 p-1">
                        <input
                            type="text"
                            value={businessDetails.basics.contactName}
                            onChange={e => handleInputChange('basics', 'contactName', e.target.value)}
                            placeholder=""
                            className='form-control w-100'
                            required
                        />
                        <label className="floating-label-register">{ t('SettingsPage.contactName')}</label>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className="form-group m-1 p-1">
                        <input
                            type="text"
                            value={businessDetails.basics.contactNumber}
                            onChange={e => handleInputChange('basics', 'contactNumber', e.target.value)}
                            placeholder=""
                            className='form-control w-100'
                            required
                        />
                        <label className="floating-label-register">{ t('SettingsPage.contactNumber')}</label>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className="form-group m-1 p-1">
                        <input
                            type="email"
                            value={businessDetails.basics.contactEmail}
                            onChange={e => handleInputChange('basics', 'contactEmail', e.target.value)}
                            placeholder=""
                            className='form-control w-100'
                            required
                        />
                        <label className="floating-label-register">{ t('SettingsPage.emailAddress')}</label>
                        </div>
                    </div>
                    <div className='col-sm-12'>
                        <div className="form-group m-1 p-1">
                        <input
                            type="text"
                            value={businessDetails.basics.contactAddress}
                            onChange={e => handleInputChange('basics', 'contactAddress', e.target.value)}
                            placeholder=""
                            className='form-control w-100'
                            required
                        />
                        <label className="floating-label-register">{ t('SettingsPage.address')}</label>
                        </div> 
                    </div>
                </div>

                
            </div>

            <div className='card mt-3  border-radius-20'>
                <h4 className='text-center mt-3'>{ t('SettingsPage.invoiceTemplateDetails')}</h4>
                <hr className='mr-5 ml-5'></hr>
                <div className='row mt-2'>
                    <div className='col-sm-6'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="text"
                                value={businessDetails.invoiceTemplateDetails.companyName}
                                onChange={e => handleInputChange('invoiceTemplateDetails', 'companyName', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                required
                            />
                            <label className="floating-label-register">{ t('SettingsPage.companyName')}</label>
                        </div> 
                    </div>
                    <div className='col-sm-6'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="email"
                                value={businessDetails.invoiceTemplateDetails.emailAddress}
                                onChange={e => handleInputChange('invoiceTemplateDetails', 'emailAddress', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                required
                            />
                            <label className="floating-label-register">{ t('SettingsPage.emailAddress')}</label>
                        </div>  
                    </div>
                    <div className='col-sm-4'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="text"
                                value={businessDetails.invoiceTemplateDetails.contactNumber}
                                onChange={e => handleInputChange('invoiceTemplateDetails', 'contactNumber', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                required
                            />
                            <label className="floating-label-register">{ t('SettingsPage.contactNumber')}</label>
                        </div>                         
                    </div>
                    <div className='col-sm-4'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="text"
                                value={businessDetails.invoiceTemplateDetails.regNumber}
                                onChange={e => handleInputChange('invoiceTemplateDetails', 'regNumber', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                required
                            />
                            <label className="floating-label-register">{ t('SettingsPage.regNumber')}</label>
                        </div> 
                    </div>
                    <div className='col-sm-4'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="text"
                                value={businessDetails.invoiceTemplateDetails.vatNumber}
                                onChange={e => handleInputChange('invoiceTemplateDetails', 'vatNumber', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                required
                            />
                            <label className="floating-label-register">{ t('SettingsPage.vatNumber')}</label>
                        </div> 
                    </div>
                    <div className='col-sm-12'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="text"
                                value={businessDetails.invoiceTemplateDetails.companyAddress}
                                onChange={e => handleInputChange('invoiceTemplateDetails', 'companyAddress', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                required
                            />
                            <label className="floating-label-register">{ t('SettingsPage.address')}</label>
                        </div> 
                    </div>
                </div>

                <div className='d-flex justify-content-end'>
                <button
                    type="button"
                    className='btn btn-primary btn-w25-w50 p-2 mb-4 mt-2 rounded-md'
                    onClick={handleTaxBtnClick}>
                        { t('SettingsPage.manageTaxOptions')}
                </button>
                </div>

            </div>

            <div className='card mt-3 border-radius-20 pb-3'>
                <h4 className='text-center mt-3'>{ t('SettingsPage.integrations')}</h4>
                <hr className='mr-5 ml-5'></hr>

                <div className='row'>
                    <div className='col-sm-12'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="password"
                                value={businessDetails.integrations.backMarket}
                                onChange={e => handleInputChange('integrations', 'backMarket', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                autocomplete="off"
                            />
                            <label className="floating-label-register">{ t('SettingsPage.backMarketApi')}</label>
                        </div> 
                    </div>
                    <a href="/guide#backmarket" target="_blank" className='text-dark text-left how-to-get-api-link ml-3'>{ t('SettingsPage.howToGetBackMarketApiKey')}</a>
                    <div className='col-sm-12'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="password"
                                value={businessDetails.integrations.refurbed}
                                onChange={e => handleInputChange('integrations', 'refurbed', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                autocomplete="off"
                            />
                            <label className="floating-label-setting">{ t('SettingsPage.refurbedApi')}</label>
                        </div> 
                    </div>
                    <a href="/guide#refurbed" target="_blank" className='text-dark text-left how-to-get-api-link ml-3'>{ t('SettingsPage.howToGetRefurbedApiKey')}</a> 
                </div>
            </div>
            
            <div className='d-flex justify-content-center'>
                <input type="submit" className='btn btn-dark w-50 p-3 mt-5 mb-3 rounded-md bg-blue' value={ t('SettingsPage.saveSettings')}/>
            </div>
            </form>

        </div>
    )
}