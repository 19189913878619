import { createSlice } from '@reduxjs/toolkit';

const getCurrentLanguageFromStorage = () => {
  try {
    const storedValue = localStorage.getItem('currentLanguageCode');
    return storedValue ? JSON.parse(storedValue) : 'en';
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return 'en';
  }
};

const languageSlice = createSlice({
    name: 'currentLanguageCode',
    initialState: {
        currentLanguageCode: getCurrentLanguageFromStorage(),
    },
    reducers: {
      setLanguage: (state, action) => {
        state.currentLanguageCode = action.payload;
        localStorage.setItem('currentLanguageCode', JSON.stringify(action.payload));
      },
      syncLanguage: (state) => {
        state.currentLanguageCode = getCurrentLanguageFromStorage();
      },
    },
  });
  
  export const { setLanguage, syncLanguage } = languageSlice.actions;
  export default languageSlice.reducer;
  