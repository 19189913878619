import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/services.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import firstImage from '../images/guide.png'
import bmlogo from '../images/bm-logo.png'
import rflogo from '../images/rf-logo.png'
import guideVideo from '../images/Guide-video.mp4'
import bmVideo from '../images/backmarket-key.mp4'
import { useTranslation } from 'react-i18next';

export default function Guide() {
  const { t } = useTranslation();
  const [menuHeight, setMenuHeight] = useState(0);
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  const templateText = `
    Dear Refurbed Integration Team,
    
    I am writing to request an API key for my Refurbed store to integrate with an invoice automation program. 
    Please provide me with the necessary details and credentials to proceed.

    Thank you,
    [Your Name]
    [Your Contact Information]
  `;

  const handleCopy = () => {
    navigator.clipboard.writeText(templateText)
      .then(() => alert('Text copied to clipboard!'))
      .catch((err) => alert('Failed to copy text: ', err));
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, []);

  return (
    <>
    <HomepageMenu />
    <Helmet>
        <title>{ t('userGuide')}</title>
                <meta name="description" content="Follow this step-by-step guide to set up and make the most of your Flexmerce account. " />

            </Helmet>
      <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
      <div className="container services-main-container">
        <div className='row landing-container'>
          
          <div className='col-sm-6 landing-container-image services-first-image-div'>
            <img src={firstImage} alt='autopilot' className='services-first-image' />
          </div>
          <div className='col-sm-6 landing-container-text'>
            <h1 className='first-title-container font-orange'>{ t('userGuides')}</h1>
            <p className='mt-4 font-light home-services-p'>
            { t('followStep')}
            </p>
            <a href='/registerBusiness'>
              <button className='btn-services-calculate-savings'>
              { t('freeTrialButton')}
              </button>
            </a>
            <a href='/appointment'>
              <button className='btn-services-contact'>
              { t('bookADemo')}
              </button>
            </a>
          </div>
        </div>

        <div className="testimonial-section mt-5 mb-5" id="flexmerce">
            <div className="marketplace-tile services-why-choose-us-tile">
                <h2 className="first-title-container font-orange text-center">{ t('learnHowTo')}</h2>
                <p className='mb-4 mt-2 text-center'>{ t('seeHow')}</p>
                <hr></hr>
                <div className='row'>
                    <div className='col-sm-8'>
                        <div className="video-container">
                            <video
                                controls
                                className='guide-video'
                            >
                                <source src={guideVideo} type="video/mp4" />
                                { t('browserNotSupport')}
                            </video>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mt-3'>
                          <ol>
                            <li className='home-services-p-1rem text-light text-left' dangerouslySetInnerHTML={{__html: t('loginToAccount')}}/>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('setUpBusiness')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('manageTax')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('automateInvoicing')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('createAndManage')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('contactUsFor')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('bookADemo')}
                            </li>
                          </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="testimonial-section mt-5 mb-5" id="backmarket">
            <div className="marketplace-tile services-why-choose-us-tile">
                <h2 className="first-title-container font-orange text-center">{ t('getBackMarketKey')}</h2>
                <p className='mb-4 mt-2 text-center' dangerouslySetInnerHTML={{__html: t('seeHowBM')}}/>
                <hr></hr>
                <div className='row'>
                    <div className='col-sm-8'>
                        <div className="video-container">
                            <video 
                                controls
                                className='guide-video'
                            >
                                <source src={bmVideo} type="video/mp4" />
                                { t('browserNotSupport')}
                            </video>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mt-3'>
                          <ol>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('loginBM')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('goTo')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('goToBM')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('clickBM')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('selectBM')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('clickConfirm')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('yourAPI')}
                            </li>
                          </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="testimonial-section mt-5 mb-5" id="refurbed">
            <div className="marketplace-tile services-why-choose-us-tile">
                <h2 className="first-title-container font-orange text-center">{ t('getRefurbedKey')}</h2>
                <p className='mb-4 mt-2 text-center'dangerouslySetInnerHTML={{__html: t('useFollowing')}}/>
                <hr></hr>
                <div className='row'>
                    <div className='col-sm-8'>
                    <div className="email-template-container" style={{ padding: '1rem', borderRadius: '8px', border: '1px solid #ddd', backgroundColor: '#f5f7fa', maxWidth: '700px', margin: '1rem auto', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <h4 style={{ color: '#ff8a00', marginBottom: '1rem', textAlign: 'center' }}>Email Template</h4>
      <div
        style={{
          whiteSpace: 'pre-wrap',
          fontSize: '0.9rem',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
          backgroundColor: '#fff',
          padding: '1rem',
          borderRadius: '4px',
          border: '1px dashed #ccc',
          marginBottom: '1rem',
          overflow: 'auto',
        }}
      >
        {templateText}
      </div>
      <button
        onClick={handleCopy}
        style={{
          display: 'block',
          width: '100%',
          padding: '10px',
          fontSize: '16px',
          fontWeight: 'bold',
          textAlign: 'center',
          color: '#fff',
          backgroundColor: '#ff8a00',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = '#e67e00')}
        onMouseOut={(e) => (e.target.style.backgroundColor = '#ff8a00')}
      >
        { t('copyTo')}
      </button>
    </div>
                    </div>
                    <div className='col-sm-4'>
                      <ol>
                        <li class="home-services-p-1rem text-light text-left">
                        { t('sendEmail')}
                        </li>
                        <li class="home-services-p-1rem text-light text-left">
                        <a href='mailto:integrations@refurbed.com' className='guide-login-a-li font-orange text-decoration-none'>
                        integrations@refurbed.com
                        </a>
                        </li>
                        <li class="home-services-p-1rem text-light text-left">
                        { t('refurbedLink')}
                        </li>
                        <li class="home-services-p-1rem text-light text-left">
                        { t('linkValid')}
                        </li>
                        <li class="home-services-p-1rem text-light text-left">
                        { t('openLink')}
                        </li>
                      </ol>
                    </div>
                </div>
            </div>
        </div>

        <div className='benefits-container mt-5 pt-3 pb-5 text-center'>
          <h1 className='mb-4 experience-title font-orange'>{ t('howWe')}</h1>
          <p className=' font-light home-services-p'>
          { t('discover')}
          </p>

          <div className='row mt-3'>
            <div className='col'></div>
            <div className='col-sm-3 marketplace-tile m-2'>
              <div className="services-icon">
                <img src={bmlogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy'/>
              </div>
              <h4 className='text-900 font-orange mb-4'>{ t('bmInt')}</h4>
              <p className='home-services-p-1rem p-2'>{ t('ourBMInt')}</p>
                <ul>
                {Array.from({ length: 5 }, (_, index) => (
                  <li className='home-services-p-1rem' key={index}>
                  {t(`bmIntegration.${index}.description`)}
                  </li>
                ))}
                </ul>
            </div>
            <div className='col-sm-3 marketplace-tile m-2'>
            <div className="services-icon">
                <img src={rflogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy' />
              </div>
                <h4 className='text-900 font-orange mb-4'>{ t('refurbedInt')}</h4>
              <p className='home-services-p-1rem p-2'>{ t('ourRefurbed')}</p>
              <ul>
              {Array.from({ length: 5 }, (_, index) => (
                  <li className='home-services-p-1rem' key={index}>
                  {t(`refurbedIntegration.${index}.description`)}
                  </li>
              ))}
              </ul>
            </div>
            <div className='col'></div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/calculateSavings">
                <button className="btn-services-calculate-savings">{ t('predictSavingsButton')}</button>
          </a>
        </div>
        </div>
        <ContactSection />
      </div>
      <Footer />
    </div>
    </>
  )
}