import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/login.css'
import 'bootstrap'

import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';

import firstImage from '../images/services-landing.png'
import bmlogo from '../images/bm-logo.png'
import rflogo from '../images/rf-logo.png'
import rklogo from '../images/rk-logo.png'
import fnlogo from '../images/fn-logo.png'
import cdlogo from '../images/cd-logo.png'
import sflogo from '../images/sf-logo.png'
import guideVideo from '../images/Guide-video.mp4'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFileZipper, faClock, faEuroSign, faListCheck, faScrewdriverWrench, faToolbox, faPhoneVolume, faRepeat, faFileShield, faHandHoldingDollar} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';


export default function SparkAILanding() {
  const [menuHeight, setMenuHeight] = useState(0);
  const images = [bmlogo, rflogo, rklogo, fnlogo, cdlogo, sflogo];
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  const { t } = useTranslation();

  const faqs = [
    {
      question: t('faqs.0.question'),
      answer: t('faqs.0.answer'),
    },
    {
      question: t('faqs.1.question'),
      answer: t('faqs.1.answer'),
    },
    {
      question: t('faqs.2.question'),
      answer: t('faqs.2.answer'),
    },
    {
      question: t('faqs.3.question'),
      answer: t('faqs.3.answer'),
    },
    {
      question: t('faqs.4.question'),
      answer: t('faqs.4.answer'),
    },
    {
      question: t('faqs.5.question'),
      answer: t('faqs.5.answer'),
    },
  ];


  const testimonials = [
    {
      text: t('testimonials.0.text'),
      customer: t('testimonials.0.customer'),
      company: t('testimonials.0.company'),
    },
    {
      text: t('testimonials.1.text'),
      customer: t('testimonials.1.customer'),
      company: t('testimonials.1.company'),
    },
    {
      text: t('testimonials.2.text'),
      customer: t('testimonials.2.customer'),
      company: t('testimonials.2.company'),
    },
    {
      text: t('testimonials.3.text'),
      customer: t('testimonials.3.customer'),
      company: t('testimonials.3.company'),
    },
    {
      text: t('testimonials.4.text'),
      customer: t('testimonials.4.customer'),
      company: t('testimonials.4.company'),
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // Slide every 5 seconds
    return () => clearInterval(interval);
  }, [nextSlide]);
  return (
    <>
    <HomepageMenu />

    <Helmet>
      <title>{t('automationDescription') }</title>
      <meta name="description" content="Automate invoicing for marketplaces like Back Market, Refurbed, Rakuten, and Fnac with our seamless solution. Instantly generate, upload, and send invoices to your customers, reducing manual work and errors. Improve efficiency, boost customer satisfaction, and streamline your order processing to focus on growing your e-commerce business." />

    </Helmet>
    <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
      <div className="container">
      <div className="row text-center">
        <div className="col-sm-6 landing-container-text">
          <div className="typewriter">
            <h1 className="gradient-text font-bold text-white">
              FLEXMERCE<span className="caret"></span>
            </h1>
          </div>
          <div className="mt-4">
            <h1 className="reveal-text">
                  <span className="font-orange p-3">{t('subHeading')}</span>
            </h1>
          </div>
          <a href="/registerBusiness">
            <button className="btn-services-calculate-savings">{t('freeTrialButton')}</button>
          </a>
          <a href="/calculatesavings">
            <button className="btn-services-contact">{t('predictSavingsButton')}</button>
          </a>
              <p className="mt-5" dangerouslySetInnerHTML={{ __html: t('title')}} />
        </div>
        <div className="col-sm-6 mx-auto landing-container-image">
          <img src={firstImage} alt="autopilot" className="img-fluid" />
        </div>
      </div>


        <div className="w-100 relative z-10 d-flex align-content-center">
          <div className="absolute"></div>
            <div className="logo-row relative flex justify-between items-center max-w-4xl mx-auto px-4 py-8 pr-5 pl-5 pb-2  footerContainer">
              {images.map((image, index) => (
                <div key={index} className="w-30 h-30 flex items-center justify-center bg-white bg-opacity-10 rounded-full p-2 ml-2 platformIcon">
                  <div className="relative w-16 h-16">
                    <img src={image} alt={`Logo ${index + 1}`} className="object-contain w-full h-full" loading='lazy' />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <hr></hr>

        <h1 className='first-title-container font-orange text-center mt-5'>{t('whatWeDo.title')}</h1>
        <p className='text-center pr-4 pl-4 home-services-p' dangerouslySetInnerHTML={{ __html: t('automationDescription') }} />
        <div className="testimonial-section mt-5 mb-5" id="flexmerce">
            <div className="marketplace-tile services-why-choose-us-tile">
                <hr></hr>
                <div className='row'>
                    <div className='col-sm-8'>
                        <div className="video-container">
                            <video
                                controls
                                className='guide-video'
                            >
                      <source src={guideVideo} type="video/mp4" />
                    {t('browserNotSupport')}
                    </video>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mt-3'>
                          <ol>
                            <li className='home-services-p-1rem text-light text-left' dangerouslySetInnerHTML={{ __html: t('loginToAccount')}} />
                            <li className='home-services-p-1rem text-light text-left'>
                            {t('setUpBusiness')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            {t('manageTax')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            {t('automateInvoicing')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            {t('createAndManage')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            {t('contactUsFor')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            {t('bookADemo')}
                            </li>
                          </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col'></div>
            <div className='col-sm-3 marketplace-tile m-2'>
              <div className="services-icon">
                <img src={bmlogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy'/>
              </div>
              <a href="/backmarket"><h4 className='text-900 font-orange mb-4'>{ t('bmInt')}</h4></a>
              <ul>
                {Array.from({ length: 5 }, (_, index) => (
                  <li key={index}>
                  {t(`bmIntegration.${index}.description`)}
                  </li>
                ))}
              </ul>
            </div>
            <div className='col-sm-3 marketplace-tile m-2'>
            <div className="services-icon">
                <img src={rflogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy' />
              </div>
              <a href="/refurbed"><h4 className='text-900 font-orange mb-4'>{ t('refurbedInt')}</h4></a>
              <ul>
              {Array.from({ length: 5 }, (_, index) => (
                  <li key={index}>
                  {t(`refurbedIntegration.${index}.description`)}
                  </li>
                ))}
              </ul>
            </div>
            <div className='col'></div>
        </div>
        
            
        <div className='row'>
          <div className='col-sm-3'>
            <a href='/services' className='text-center mb-3 w-100'>
              <button className='btn-services-calculate-savings'>
                {t('services')}
              </button>
            </a>
          </div>
          
          <div className='col-sm-3'>
            <a href='/blogs' className='text-center mb-3 w-100'>
              <button className='btn-services-calculate-savings'>
                {t('readInsights')}
              </button>
            </a>
          </div>
        </div>

        <hr></hr>

        <h1 className='first-title-container font-orange text-center mt-5 mb-3'>{t('whyChooseFlexmerce')}</h1>

        <div className="home-services-container">
          <p className='text-center pr-4 pl-4 home-services-p'>{t('atFlexmerce')}</p>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faRepeat} alt="Repeat" />
            </div>
              <h4>{t('instantInvoicing') }</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faFileShield} alt="File Shield" />
            </div>
            <h4>{t('secureDataHandling') }</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faHandHoldingDollar} alt="Holding Dollar" />
            </div>
            <h4>{t('lowerFees') }</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faEuroSign} alt="Increased profit" />
            </div>
            <h4>{t('higherBackBox') }</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faListCheck} alt="Automate" />
            </div>
            <h4>{t('automateProcess') }</h4>
          </div>
          <div className="home-services-tile">
            <div className="services-icon">
              <FontAwesomeIcon icon={faClock} alt="Clock" />
            </div>
            <h4>{t('saveTime') }</h4>
          </div>
          <div className='home-services-tile'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faScrewdriverWrench} alt='Troubleshooting' />
              </div>
              <h4>{t('customTroubleshooting') }</h4>
          </div>
          <div className='home-services-tile'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faToolbox}  alt='Maintenance' />
              </div>
              <h4>{t('ongoingMaintenance') }</h4>
          </div>
          <div className='home-services-tile'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faPhoneVolume} alt='Customer service support' />
              </div>
              <h4>{t('dedicatedSupport') }</h4>
          </div>
          <div className='home-services-tile'>
              <div className="services-icon">
                <FontAwesomeIcon icon={faFileZipper} alt='File zipper' />
              </div>
              <h4>{t('exportPDFs') }</h4>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/registerBusiness">
            <button className="btn-services-calculate-savings">{t('freeTrialButton') }</button>
          </a>
        </div>
          <p className='text-center pr-4 pl-4 home-services-p mt-5 mb-5'>{t('withFlexmerce')}</p>
        <hr></hr>
        <div className="faq-section">
            <h2 className="first-title-container font-orange text-center mt-5 mb-5">{ t('faqsTitle')}</h2>
      <div className="faq-grid mb-2">
        {faqs.map((faq, index) => (
          <div className="faq-flip-card" key={index}>
            <div className="faq-card-inner">
              <div className="faq-card-front">
                <h4 className="faq-question">{faq.question}</h4>
              </div>
              <div className="faq-card-back">
                <p className="faq-answer">{faq.answer}</p>
              </div>
            </div>
          </div>
        ))}
        
      </div>

        <div className='row'>
          <div className='col-sm-6 text-right'>
          <a href="/pricing">
                  <button className="btn-services-calculate-savings mr-1" >{ t('exploreOurLowPrices')}</button>
          </a>
          </div>
          <div className='col-sm-6 text-left'>
          <a href="/guide#flexmerce">
            <button className="btn-services-calculate-savings mr-1">{ t('learnHowTo')}</button>
          </a>
          </div>
          <div className='col-sm-6 text-right'>
          <a href="/guide#backmarket">
            <button className="btn-services-calculate-savings mr-1">{ t('getBackMarketKey')}</button>
          </a>
          </div>
          <div className='col-sm-6 text-left'>
          <a href="/guide#refurbed">
            <button className="btn-services-calculate-savings mr-1">{ t('getRefurbedKey')}</button>
          </a>
          </div>
        </div>

      </div>

      <hr></hr>

      <div className="testimonial-section mt-3 mb-5">
            <h2 className="first-title-container font-orange text-center mt-5 mb-5">{t('testimonialsTitle')}</h2>
        <div className="testimonial-tile">
          <div className="tile-content home-testimonial-tile">
            <p className="testimonial-text">"{testimonials[currentIndex].text}"</p>
            <h4 className="testimonial-company text-light">{testimonials[currentIndex].customer}</h4>
          </div>
        </div>
        <div className="testimonial-controls">
          <button className="prev-slide" onClick={prevSlide}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <div className="testimonial-dots">
            {testimonials.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? "active" : ""}`}
                onClick={() => goToSlide(index)}
              ></span>
            ))}
          </div>
          <button className="next-slide" onClick={nextSlide}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>

      <ContactSection />
      </div>
      <Footer/>
    </div>
    </>
  )
}