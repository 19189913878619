
import 'bootstrap'
import '../css/dashboard.css';
import CheckAuth from '../hooks/checkAuth';

export default function Reports() {
    CheckAuth();
    return (
        <div className='container'>
        <h2 className='mt-3 mb-4 fw-bold'>Reports</h2>
                        <table className='mt-4 table text-center' id="main-table">
                            <thead>
                                <tr>
                                <th>Invoice No</th>
                                <th>Order Ref</th>
                                <th>Date</th>
                                <th>Customer Name</th>
                                <th>Qty</th>
                                <th>Amount</th>
                                <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>54854</td>
                                    <td>58475859</td>
                                    <td>20-05-2024</td>
                                    <td>Topweb Limited</td>
                                    <td>1</td>
                                    <td>£254.55</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>54854</td>
                                    <td>58475859</td>
                                    <td>20-05-2024</td>
                                    <td>Topweb Limited</td>
                                    <td>1</td>
                                    <td>£254.55</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>54854</td>
                                    <td>58475859</td>
                                    <td>20-05-2024</td>
                                    <td>Topweb Limited</td>
                                    <td>1</td>
                                    <td>£254.55</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>54854</td>
                                    <td>58475859</td>
                                    <td>20-05-2024</td>
                                    <td>Topweb Limited</td>
                                    <td>1</td>
                                    <td>£254.55</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>54854</td>
                                    <td>58475859</td>
                                    <td>20-05-2024</td>
                                    <td>Topweb Limited</td>
                                    <td>1</td>
                                    <td>£254.55</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>54854</td>
                                    <td>58475859</td>
                                    <td>20-05-2024</td>
                                    <td>Topweb Limited</td>
                                    <td>1</td>
                                    <td>£254.55</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>54854</td>
                                    <td>58475859</td>
                                    <td>20-05-2024</td>
                                    <td>Topweb Limited</td>
                                    <td>1</td>
                                    <td>£254.55</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>54854</td>
                                    <td>58475859</td>
                                    <td>20-05-2024</td>
                                    <td>Topweb Limited</td>
                                    <td>1</td>
                                    <td>£254.55</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>                
        </div>
    )
}