import { createSlice } from '@reduxjs/toolkit';

const getOnIntegeration = () => {
  try {
    const storedValue = localStorage.getItem('isIntegrationSetup');
    return storedValue ? JSON.parse(storedValue) : false;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return false;
  }
};
const integrationSlice = createSlice({
    name: 'integrationSetup',
    initialState: {
        isIntegrationSetup: getOnIntegeration(),
    },
    reducers: {
      setIntegration: (state, action) => {
        state.isIntegrationSetup = action.payload;
        localStorage.setItem('isIntegrationSetup', JSON.stringify(action.payload));
      },
      syncIntegration: (state) => {
        state.isIntegrationSetup = getOnIntegeration();
      },
    },
  });
  
  export const { setIntegration, syncIntegration } = integrationSlice.actions;
  export default integrationSlice.reducer;
  