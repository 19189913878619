import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is correctly imported if you're using it
import '../css/login.css';
import backgroundImage from '../images/background.jpg';
import { useAuth } from '../context/AuthContext';

export default function AdminLogin() {
    const sectionStyle = {
        width: "100vw",
        height: "100vh",
        maxHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ email: '', password: '' });
    const [incorrectCredentials, setIncorrectCredentials] = useState(false);

    const { login } = useAuth();

    const validateEmail = () => {
        let tempErrors = { email: '' };
        let formIsValid = true;

        // Simple email validation
        if (!email) {
        formIsValid = false;
        tempErrors['email'] = 'Email is required';
        }

        setErrors(tempErrors);
        return formIsValid;
    }

    const validatePassword = () => {
        let tempErrors = { password: '' };
        let formIsValid = true;

        // Simple password validation
        if (!password) {
            formIsValid = false;
            tempErrors['password'] = 'Password is required';
            }

        setErrors(tempErrors);
        return formIsValid;
    }

    const validateForm = () => {
        return validateEmail() && validatePassword();
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/checkAdminCredentials`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({email, password})
                });
    
                if (!response.ok) {
                    if (response.status === 404 || response.status === 401) {
                        setIncorrectCredentials(true);
                    } else {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                } else {
                    const data = await response.json();
                    localStorage.setItem('adminId', data.admin); // Optionally store user data or token
                    localStorage.setItem('accessToken', data.accessToken);
                    login("admin");  // Update authentication state to true
                    navigate('/registerbusiness');
                }
                
            } catch (error) {
                console.error('Failed to fetch:', error);
            }
        }
    };
    

    return (
        <>
            <div className="login-container" style={sectionStyle}>
                <div className="login-card">
                    <h2 className=' fw-bold'>Admin Log in</h2>
                    <hr className='m-4'></hr>
                    <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        onBlur={validateEmail}
                        className={`form-control p-4 ${errors.email ? 'is-invalid' : ''}`}
                        placeholder="Email"
                        autoFocus
                        />
                        <div className="invalid-feedback">{errors.email}</div>
                    </div>
                    <div className="form-group">
                        <input
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onBlur={validatePassword}
                        className={`form-control p-4 ${errors.password ? 'is-invalid' : ''}`}
                        placeholder="Password"
                        />
                        <div className="invalid-feedback">{errors.password}</div>
                    </div>
                    
                    
                    {incorrectCredentials && (
                        <div className="alert alert-danger p-2 text-center justify-content-center" role="alert">
                            Incorrect Credentials!
                        </div>
                    )}

                    <div className="form-group">
                        <button type="submit" className="btn btn-dark w-100 p-3 bg-blue">Login</button>
                    </div>

                    <div className="form-group">
                        <a href="/forgotPassword" className="forgot-password">Forgot password?</a>
                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}