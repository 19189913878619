import React, { useState, useEffect, useRef  } from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap'
import '../css/dashboard.css';
import CheckAuth from '../hooks/checkAuth';
import axiosInstance from '../components/axio';
import { Circles } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';

export default function JobHistories() {
    CheckAuth();
    const { t } = useTranslation();
    const [jobHistories, setJobHistories] = useState([]);  // Initialize as an array
    const [loading, setLoading] = useState(false);
    const hasFetched = useRef(false);
    useEffect(() => {
        if (!hasFetched.current) {
            // Run only once
            hasFetched.current = true;

            async function fetchJobHistories() {
                setLoading(true);
                const userId = localStorage.getItem('userId'); // Retrieve userId from localStorage
                if (userId) {
                    try {
                        const response = await axiosInstance.get(`/jobOption/history/${userId}`);
                        if (response.status !== 200) {
                            throw new Error('Failed to fetch Tax options');
                        }
                        const data = await response.data;
                        setJobHistories(Array.isArray(data) ? data : []);
                    } catch (error) {
                        console.error('Error:', error);
                    } finally {
                        setLoading(false);
                    }
                }
            }

            fetchJobHistories();
        }
    }, []);

    const platformMapping = {
        2: 'Back Market',
        3: 'Refurbed'
    };

    return (
        <div className='container mb-5'>
            <Helmet>
                <title>{ t('HistoryPage.automationHistories')}</title>
            </Helmet>
            {loading && (
                <div className='loader-overlay'>
                    <div className='loader-container'>
                        <Circles
                            height="80"
                            width="80"
                            color="#ff8a00"
                            ariaLabel="circles-loading"
                            visible={true}
                        />
                    </div>
                </div>
            )}
            <h2 className='mt-3 fw-bold'>{ t('HistoryPage.automationHistories')}</h2>

            <h5 className='mt-4 fw-bold text-center'>{ t('HistoryPage.schedule')}</h5>
            <div className=''>
                <table className='mt-1 table table-custom-radius text-center bg-transparent' id="main-table">
                    <thead>
                        <tr>
                            <th className='text-light bg-blue'>{ t('HistoryPage.platform')}</th>
                            <th className='text-light bg-blue'>{ t('HistoryPage.monday')} - { t('HistoryPage.friday')}</th>
                            <th className='text-light bg-blue'>{ t('HistoryPage.saturday')} - { t('HistoryPage.sunday')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-label={ t('HistoryPage.platform')}>Back Market</td>
                            <td data-label={ t('HistoryPage.monday-friday')}>
                                00:00 - 08:59 - { t('HistoryPage.every6Hours')} <br></br>
                                09:00 - 18:59 - { t('HistoryPage.every2Hours')} <br></br>
                                18:00 - 23:59 - { t('HistoryPage.every6Hours')} <br></br>
                            </td>
                            <td data-label={ t('HistoryPage.saturday-sunday')}>
                                00:00 - 23:59 - { t('HistoryPage.every6Hours')} <br></br>
                            </td>
                        </tr>
                        <tr>
                            <td data-label={ t('HistoryPage.platform')}>Refurbed</td>
                            <td data-label={ t('HistoryPage.monday-friday')}>
                                00:00 - 09:59 - { t('HistoryPage.every6Hours')} <br></br>
                                09:00 - 18:59 - { t('HistoryPage.every2Hours')} <br></br>
                                19:00 - 23:59 - { t('HistoryPage.every6Hours')} <br></br>
                            </td>
                            <td data-label={ t('HistoryPage.saturday-sunday')}>
                                00:00 - 23:59 - { t('HistoryPage.every6Hours')} <br></br>
                            </td>
                        </tr>         
                    </tbody>
                </table>
            </div>



            <h5 className='mt-4 fw-bold text-center'>{ t('HistoryPage.last7DaysHistories')}</h5>
            <div className=''>
                <table className='mt-1 table table-custom-radius text-center bg-transparent' id="main-table">
                    <thead>
                        <tr>
                            <th className='text-light bg-blue'>{ t('HistoryPage.dateTime')}</th>
                            <th className='text-light bg-blue'>{ t('HistoryPage.platform')}</th>
                            <th className='text-light bg-blue'>{ t('HistoryPage.NnoOfInvoices')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobHistories.map((jobHistory, index) => {
                            return (
                                <tr key={index}>
                                    <td data-label="Date Time">{new Date(jobHistory.dateTime).toLocaleString('en-GB', { 
                                        day: '2-digit', 
                                        month: '2-digit', 
                                        year: 'numeric', 
                                        hour: '2-digit', 
                                        minute: '2-digit',
                                        hour12: false 
                                    })}</td>
                                    <td data-label="Platform">{platformMapping[jobHistory.platformId]}</td>
                                    <td data-label="No. of invoices">{jobHistory.numberOfInvoices}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    )
}