import { configureStore } from '@reduxjs/toolkit';
import onboardSliceReducer from '../slices/onboardSlice';
import profileSetupReducer from '../slices/profileSlice';
import integrationSlice from '../slices/integrationSlice';
import invoiceTemplateSlice from '../slices/invoiceTemplateSlice';
import languageSlice from '../slices/languageSlice'


const store = configureStore({
  reducer: {
    onboard: onboardSliceReducer,
    onProfile: profileSetupReducer,
    onIntegrationSlice: integrationSlice,
    onInvoiceTemplateSlice: invoiceTemplateSlice,
    onlanguage: languageSlice,
  },
});

export default store;
