import React, { useEffect, useRef, useState } from 'react';
import axiosInstance from '../components/axio';
import { setIntegration } from "../redux/slices/integrationSlice";
import { useDispatch } from 'react-redux';
import { Circles } from 'react-loader-spinner';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Integrations() {
    const { t } = useTranslation();
    const userId = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const hasFetched = useRef(false);
    const [businessDetails, setBusinessDetails] = useState({
        basics: {
            businessName: '',
            contactName: '',
            contactNumber: '',
            contactAddress: '',
            contactEmail: '',
            userId: userId
        },
        invoiceTemplateDetails: {
            companyName: '',
            contactNumber: '',
            emailAddress: '',
            regNumber: '',
            vatNumber: '',
            companyAddress: ''
        },
        integrations: {
            backMarket: '',
            refurbed: '',
        }
    });


    useEffect(() => {
        if (!hasFetched.current) {
            // Run only once
            hasFetched.current = true;
            async function fetchBusinessDetails() {
                setLoading(true);
                if (userId) {
                    try {
                        const response = await axiosInstance.get(`/businesses/${userId}`);
                        if (response.status !== 200) {
                            throw new Error('Failed to fetch business data');
                        }
                        const data = await response.data;
                        setBusinessDetails(data);
                        setLoading(false);
                    } catch (error) {
                        setLoading(false);
                        console.error('Error:', error);
                    }
                }
            }
            fetchBusinessDetails();
        }
    }, [userId]);

    const handleInputChange = (section, key, value) => {
        setBusinessDetails(prevState => ({
            ...prevState,
            [section]: { ...prevState[section], [key]: value },
        }));
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        if (businessDetails.integrations.backMarket === "" && businessDetails.integrations.refurbed === "") {
            alert("Please enter at least one API key!");
            setLoading(false);
        } else {
            try {
                const response = await axiosInstance.put(`/businesses/${userId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(businessDetails)
                });
                if (response.status !== 200) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                setLoading(false);
                dispatch(setIntegration(true));
                window.close();
            } catch (error) {
                setLoading(false);
                console.error('Failed to update settings:', error);
                alert('Failed to update settings.');
            }
        }
    };

    return (
        <div className='container mb-5'>
        <Helmet>
            <title>API Keys</title>
        </Helmet>
        {loading && (
            <div className='loader-overlay'>
                <div className='loader-container'>
                    <Circles
                        height="80"
                        width="80"
                        color="#ff8a00"
                        ariaLabel="circles-loading"
                        visible={true}
                    />
                </div>
            </div>
        )}
        <h2 className='mt-3 mb-4 fw-bold'>{ t('SettingsPage.settings')}</h2>
        <form onSubmit={handleSubmit}>
       <div className='card mt-3 border-radius-20'>
                <h4 className='text-center mt-3'>{ t('SettingsPage.integrations')}</h4>
                <hr className='mr-5 ml-5'></hr>

                <div className='row mb-4'>
                    <div className='col-sm-12'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="password"
                                value={businessDetails.integrations.backMarket}
                                onChange={e => handleInputChange('integrations', 'backMarket', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                
                            />
                            <label className="floating-label-register">{ t('SettingsPage.backMarketApi')}</label>
                        </div>
                    </div>
                    <a href="/guide#backmarket" target="_blank" className='text-dark text-left how-to-get-api-link ml-3'>{ t('SettingsPage.howToGetBackMarketApiKey')}</a> 
                    <div className='col-sm-12'>
                        <div className="form-group m-1 p-1">
                            <input
                                type="password"
                                value={businessDetails.integrations.refurbed}
                                onChange={e => handleInputChange('integrations', 'refurbed', e.target.value)}
                                placeholder=""
                                className='form-control w-100'
                                
                            />
                            <label className="floating-label-setting">{ t('SettingsPage.refurbedApi')}</label>
                        </div> 
                    </div>
                    <a href="/guide#refurbed" target="_blank" className='text-dark text-left how-to-get-api-link ml-3'>{ t('SettingsPage.howToGetRefurbedApiKey')}</a> 
                </div>
            </div>
            
            <div className='d-flex justify-content-center'>
                <input type="submit" className='btn btn-dark w-50 p-3 mt-5 mb-3 rounded-md bg-blue' value={ t('SettingsPage.saveSettings')}/>
            </div>
            </form>
            </div>
    );
}
