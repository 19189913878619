import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import { useTranslation } from 'react-i18next';

const SidebarData = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('loggedInMenu.dashboard'),
      path: '/dashboard',
      icon: <AiIcons.AiFillDashboard />,
      cName: 'nav-text text-light'
    },
    {
      title: t('loggedInMenu.invoices'),
      path: '/invoices',
      icon: <IoIcons.IoMdFolder />,
      cName: 'nav-text'
    },
    {
      title: t('loggedInMenu.exportInvoices'),
      path: '/exportInvoices',
      icon: <IoIcons.IoMdArchive />,
      cName: 'nav-text'
    },
    {
      title: t('loggedInMenu.history'),
      path: '/jobHistories',
      icon: <IoIcons.IoIosArchive />,
      cName: 'nav-text'
    },
    {
      title: t('loggedInMenu.settings'),
      path: '/settings',
      icon: <IoIcons.IoIosSettings />,
      cName: 'nav-text setting'
    },
    {
      title: t('loggedInMenu.logout'),
      path: '/logout',
      icon: <IoIcons.IoMdExit style={{ transform: 'rotate(180deg)' }} />,
      cName: 'nav-text'
    }
  ];
}

export default SidebarData;