import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/login.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import Footer from '../components/Footer';

export default function Terms() {
    const [menuHeight, setMenuHeight] = useState(0);
    useEffect(() => {
        const menuElement = document.querySelector('.navbar');
        if (menuElement) {
        setMenuHeight(menuElement.offsetHeight);
        }
    }, []);

    return (
        <>
    <HomepageMenu />
    <Helmet>
      <title>Terms of use</title>
      <meta name="description" content="Terms of use"/>
    </Helmet>
    <div className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem" style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}>
      <div className="container mb-5">
          <div className='calculate-saving-form mt-5 text-justify'>
            <h2 className='text-900 font-orange'></h2>
            <div className='blog-content text-light'>
                <h1 className='font-orange text-center'>Terms of Use</h1>
                <p>
                Welcome to Flexmerce! These Terms of Use ("Terms") govern your access to and use of the Flexmerce website, platform, and services ("Services"). By using our Services, you agree to be bound by these Terms, so please read them carefully. If you do not agree with any part of these Terms, you may not use our Services.
                </p>
                <h3 className='font-orange'>1. Overview of Services</h3>
                <p>
                Flexmerce provides automated invoicing, data insights, and marketplace optimization services designed to support online merchants in managing their business efficiently on multiple marketplaces. Our Services are intended for professional use and to improve your operations on supported platforms.
                </p>
                <h3 className='font-orange'>2. Eligibility</h3>
                <p>
                To use Flexmerce, you must be at least 18 years old and have the legal authority to enter into these Terms on behalf of yourself or the business you represent. By using our Services, you warrant that you meet these eligibility requirements.  
                </p>
                <h3 className='font-orange'>3. Account Registration</h3>
                <p>
                To access certain features of the Services, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate and complete.
                </p>
                <ul>
                  <li>Account Security: You are responsible for maintaining the confidentiality of your account information, including your password. Notify us immediately if you suspect any unauthorized access to your account.</li>
                  <li>Free Trials and Subscriptions: Flexmerce may offer a free trial period for new users. At the end of the free trial, your account may transition to a subscription plan, depending on your preference.</li>
                </ul>
                <h3 className='font-orange'>4. Use of Services</h3>
                <p>
                You agree to use our Services in compliance with all applicable laws, rules, and regulations. Unauthorized use, abuse, or misuse of our Services is strictly prohibited, including but not limited to:
                </p>
                <ul>
                  <li>Accessing data or accounts not intended for you</li>
                  <li>Engaging in fraudulent, deceptive, or unlawful activity</li>
                  <li>Circumventing any security measures of our Services</li>
                </ul>
                <h3 className='font-orange'>5. Fees and Payment</h3>
                <p>
                Flexmerce offers subscription-based plans and pay-per-use options. By choosing a plan or service, you agree to pay all applicable fees. Flexmerce reserves the right to modify pricing or plans with notice to you. If any payment is declined or overdue, we may suspend or terminate access to our Services.
                </p>
                <h3 className='font-orange'>6. Intellectual Property</h3>
                <p>
                All content, software, and materials associated with Flexmerce, including but not limited to text, images, graphics, and logos, are protected by intellectual property laws. You may not use, reproduce, or distribute our intellectual property without express written permission.
                </p>
                <h3 className='font-orange'>7. Privacy Policy</h3>
                <p>
                Please review our [Privacy Policy](link to privacy policy), which explains how we collect, use, and share your information
                </p>
                <h3 className='font-orange'>8. Limitation of Liability</h3>
                <p>
                To the fullest extent permitted by law, Flexmerce and its affiliates, officers, employees, agents, and partners are not liable for any indirect, incidental, special, consequential, or punitive damages, including lost profits, revenue, data, or business opportunities, even if we have been advised of the possibility of such damages.
                </p>
                <h3 className='font-orange'>9. Warranties and Disclaimers</h3>
                <p>
                Flexmerce provides its Services "as is" and "as available." We make no warranties, express or implied, regarding the accuracy, reliability, or availability of our Services.
                </p>
                <h3 className='font-orange'>10. Indemnification</h3>
                <p>
                You agree to indemnify and hold harmless Flexmerce, its affiliates, and its officers, employees, agents, and partners from any claims, liabilities, damages, losses, or expenses, arising out of or related to your use of the Services or violation of these Terms.
                </p>
                <h3 className='font-orange'>11. Modifications to Terms</h3>
                <p>
                Flexmerce reserves the right to modify or update these Terms at any time. Any changes will be effective immediately upon posting to our website, and continued use of our Services constitutes acceptance of the revised Terms.
                </p>
                <h3 className='font-orange'>12. Governing Law and Dispute Resolution</h3>
                <p>
                These Terms are governed by the laws of United Kingdom. Any disputes arising from these Terms will be resolved in the competent courts of United Kingdom.
                </p>
                <h3 className='font-orange'>13. Contact Us</h3>
                <p>
                If you have any questions about these Terms, please contact us at:
                </p>
                <ul>
                  <li>Email: help@flexmerce.com</li>
                  <li>Phone: +44 (0) 333 789 0702</li>
                </ul>
            </div>
          </div> 
        </div> 
        <Footer />
    </div>
    </>
    )
}