import axios from 'axios';

// Axios instance
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor to attach access token
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => Promise.reject(error));

// Response interceptor to handle expired tokens
axiosInstance.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;

  // If token has expired, attempt to refresh
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh-token`);
      localStorage.setItem('accessToken', data.accessToken);
      axiosInstance.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
      return axiosInstance(originalRequest); // Retry the original request with the new token
    } catch (err) {
      console.log('Failed to refresh token:', err);
    }
  }
  return Promise.reject(error);
});

export default axiosInstance;
