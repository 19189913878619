import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css';
import '../css/services.css';
import 'bootstrap';
import HomepageMenu from '../components/HomepageMenu';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export default function SendCustomEmail() {
  const [subject, setSubject] = useState('');
  const [emails, setEmails] = useState('');
  const [htmlMessage, setHtmlMessage] = useState('');
  const [menuHeight, setMenuHeight] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }

    const pin = prompt('Please enter the PIN to access this page:');
    if (pin === 'flexmerce99786') {
      setIsAuthenticated(true);
    } else {
      window.location.href = 'https://flexmerce.com';
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formattedEmails = emails.replace(/\n/g, ',');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sendCustomEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ formattedEmails, subject, htmlMessage }),
      });

      if (!response.ok) {
        if (response.status === 404 || response.status === 401) {
          alert('Error sending your details :(');
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } else {
        alert('Email sent!');
        setSubject('');
        setEmails('');
        setHtmlMessage('');
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  if (!isAuthenticated) return null;

  return (
    <>
      <HomepageMenu />
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Get in touch with the Flexmerce team for inquiries, support, or to learn more about our automation solutions. We're here to help you reduce marketplace fees, streamline customer service, and drive business growth. Contact us today!" />
      </Helmet>
      <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
        <div className="comet comet-1"></div>
        <div className="comet comet-2"></div>
        <div className="comet comet-3"></div>
        <div className="w-full relative z-10"></div>

        {/* Main content */}
        <div className="text-center space-y-8 max-w-3xl mx-auto relative z-10">
          <h1 className='first-title-container font-orange mt-5'>Get in touch with us</h1>
          <p className='mt-4 font-light home-services-p'>
            Automate your invoicing with Flexmerce and reduce marketplace charges such as Back Market, Refurbed, and more.
          </p>

          <div className='myContainer'>
            <div className="benefit-tiles mx-auto padding-none mw-90">
              {/* Tile 1 */}
              <div className="home-tile padding-none">
                <h3 className='mb-5'><span className='font-orange'>Get in touch</span></h3>
                <p>
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-white" />
                  <a href='mailto:help@flexmerce.com'>help@flexmerce.com</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faPhone} className="mr-2" />
                  <a href='tel:00443337890702'>+44 333 789 0702</a>
                </p>
                <p>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                  Canary Wharf, London
                </p>
              </div>

              {/* Tile 2 */}
              <div className="home-tile contact-tile padding-none mw-90 mnw-100">
                <h3 className='mb-5 p-2'><span className='font-orange'>Let's talk! We're here to assist you</span></h3>
                <form onSubmit={handleSubmit} className='contact-form'>
                  <div>
                    <input
                      type="text"
                      value={subject}
                      className="form-control p-4 contact-form-input"
                      placeholder="Subject"
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <textarea
                      className='form-control contact-form-input'
                      rows={3}
                      name='text'
                      value={emails}
                      placeholder='Emails'
                      onChange={(e) => setEmails(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div>
                    <textarea
                      className='form-control contact-form-input'
                      rows={3}
                      name='message'
                      value={htmlMessage}
                      placeholder='htmlMessage'
                      onChange={(e) => setHtmlMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="mt-3 ">
                    <button type="submit" className="mb-3 bg-white text-black text-lg py-3 px-5 rounded-full hover:bg-gray-200 transition-all duration-300 shadow-lg hover:shadow-xl btn-login-tw">Contact us</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
