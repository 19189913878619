import { createSlice } from '@reduxjs/toolkit';

const getOnBoardedFromStorage = () => {
  try {
    const storedValue = localStorage.getItem('onBoarded');
    return storedValue ? JSON.parse(storedValue) : false;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return false;
  }
};

const onboardSlice = createSlice({
  name: 'onboard',
  initialState: {
    isOnBoarded: getOnBoardedFromStorage(),
  },
  reducers: {
    setOnBoarded: (state, action) => {
      state.isOnBoarded = action.payload;
      localStorage.setItem('onBoarded', JSON.stringify(action.payload));
    },
    syncOnBoarded: (state) => {
      state.isOnBoarded = getOnBoardedFromStorage();
    },
  },
});

export const { setOnBoarded, syncOnBoarded } = onboardSlice.actions;
export default onboardSlice.reducer;
