import { createSlice } from '@reduxjs/toolkit';

const getOnTemplateFromStorage = () => {
  try {
    const storedValue = localStorage.getItem('isTemplateSetup');
    return storedValue ? JSON.parse(storedValue) : false;
  } catch (error) {
    console.error('Error reading from localStorage:', error);
    return false;
  }
};

const templateSlice = createSlice({
    name: 'templateSetup',
    initialState: {
      isTemplateSetup: getOnTemplateFromStorage(),
    },
    reducers: {
      setTemplate: (state, action) => {
        state.isTemplateSetup = action.payload;
        localStorage.setItem('isTemplateSetup', JSON.stringify(action.payload));
      },
      syncTemplate: (state) => {
        state.isTemplateSetup = getOnTemplateFromStorage();
      },
    },
  });
  
  export const { setTemplate, syncTemplate } = templateSlice.actions;
  export default templateSlice.reducer;
  