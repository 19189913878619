import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/login.css';
import HomepageMenu from '../components/HomepageMenu';
import Footer from '../components/Footer';
export default function ResetPassword() {
    const { token } = useParams(); // Extract token from URL
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search); // Retrieves query parameters from the URL
    const status = query.get('status'); 
    const hasValidated = useRef(false);
    const isValidSession = useRef(false); 
    useEffect(() => {
            const validateToken = async () => {
                try {
                    const url = status !==null ? `verifyAccount/${token}` : `resetPassword/${token}`
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });
        
                    if (!response.ok) {
                        console.error(`${response.body}`);
                        navigate('/login'); 
                    } else if (status) {
                        navigate('/login'); 
                    } else {
                        isValidSession.current = true;
                    }
                } catch (error) {
                    console.error('Failed to fetch:', error);
                }
            }
           if (!hasValidated.current && token) {
                hasValidated.current = true;
                validateToken();
            }
            else if (!token) {
                console.error('No token found in URL');
                navigate('/login'); // Redirect to login if token is not present
            }
        
    }, [token, status, navigate]); 
    
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
    const [message, setMessage] = useState('');

    const validateForm = () => {
        let tempErrors = { password: '', confirmPassword: '' };
        let formIsValid = true;

        // Password validation
        if (!password) {
            formIsValid = false;
            tempErrors.password = 'Password is required';
        } else if (password.length < 8) {
            formIsValid = false;
            tempErrors.password = 'Password must be at least 8 characters long';
        }

        if (password !== confirmPassword) {
            formIsValid = false;
            tempErrors.confirmPassword = 'Passwords do not match';
        }

        setErrors(tempErrors);
        return formIsValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/updatePassword/${token}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ password })
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    setMessage('Your password has been reset successfully.');
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000); // Redirect to login page after 3 seconds
                }
            } catch (error) {
                console.error('Failed to reset password:', error);
            }
        }
    };

    return isValidSession? (
        <>
        <HomepageMenu />
        <Helmet>
            <title>Forgot Password</title>
        </Helmet>
        <div className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem">
        <div className="text-center space-y-8 max-w-3xl mx-auto relative z-10 mt-5">
            <div className="login-card">
                <h2 className='fw-bold'>Reset Password</h2>
                <p className='text-center'>Please enter your new password below</p>
                <hr className=''></hr>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className={`form-control p-4 ${errors.password ? 'is-invalid' : ''}`}
                            placeholder="New Password"
                        />
                        <div className="invalid-feedback">{errors.password}</div>
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={e => setConfirmPassword(e.target.value)}
                            className={`form-control p-4 ${errors.confirmPassword ? 'is-invalid' : ''}`}
                            placeholder="Confirm Password"
                        />
                        <div className="invalid-feedback">{errors.confirmPassword}</div>
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-dark w-100 p-3 bg-blue">Reset Password</button>
                    </div>
                </form>
                {message && <div className="alert alert-success mt-3 text-center">{message}</div>}
                <hr></hr>
                <div className="d-flex justify-content-center">
                    <a href="/login" className="text-decoration-none btn btn-success">Return to login</a>
                </div>
            </div>
            </div>
            <Footer />
        </div>
        </>
    ) : (
        <>
            <HomepageMenu />
             <Helmet>
            <title>Something went wrong</title>
        </Helmet>
                <Footer />
                </>
            
    );
}
