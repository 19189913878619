import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/services.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import Footer from '../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [volume, setVolume] = useState('');
  const [menuHeight, setMenuHeight] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  const handleSubmit = async (event) => {
      event.preventDefault();
      if (true) {
          try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sendContactForm`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({fullName, email, message, volume})
              });

              if (!response.ok) {
                  if (response.status === 404 || response.status === 401) {
                      alert("Error sending your details :(");
                  } else {
                      throw new Error(`HTTP error! status: ${response.status}`);
                  }
              } else {
                  alert(t('contactPageSuccess'));
                  setFullName('');
                  setEmail('');
                  setMessage('');
                  setVolume('');
              }
              
          } catch (error) {
              console.error('Failed to fetch:', error);
          }
      }
  };

  return (
    <>
    <HomepageMenu />
    <Helmet>
        <title>{t('contactUs')}</title>
      <meta name="description" content="Get in touch with the Flexmerce team for inquiries, support, or to learn more about our automation solutions. We're here to help you reduce marketplace fees, streamline customer service, and drive business growth. Contact us today!" />
    </Helmet>
    <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
    >
      <div className="comet comet-1"></div>
      <div className="comet comet-2"></div>
      <div className="comet comet-3"></div>
      <div className="w-full relative z-10"></div>

      {/* Main content */}
      <div className="text-center space-y-8 max-w-3xl mx-auto relative z-10">

      <h1 className='first-title-container font-orange mt-5'>{t('getIn')}</h1>
      <p className='mt-4 font-light home-services-p'>
      {t('automate')}
      </p>
      <p className='mt-4 font-light home-services-p' dangerouslySetInnerHTML={{__html: t('knowHowTo')}}/>
        <div className='myContainer'>
          <div className="benefit-tiles mx-auto padding-none mw-90">
            {/* Tile 1 */}
            <div className="home-tile padding-none">
              <h3 className='mb-5'><span className='font-orange'>{t('getInTouch')}</span></h3>
              <p>
                <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-white" />
                <a href='mailto:help@flexmerce.com'>help@flexmerce.com</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} className="mr-2" />
                <a href='tel:00443337890702'>+44 333 789 0702</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                Canary Wharf, London
              </p>
            </div>

            {/* Tile 2 */}
            <div className="home-tile contact-tile padding-none mw-90 mnw-100">
              <h3 className='mb-5 p-2'><span className='font-orange'>{t('letsTalk')}</span></h3>
              <form onSubmit={handleSubmit} className='contact-form'>
                    <div>
                        <input
                        type="text"
                        value={fullName}
                        className="form-control p-4 contact-form-input"
                        placeholder={t('contactFormFullName')}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                        />
                    </div>
                    <div>
                        <input
                        type="email"
                        value={email}
                        className="form-control p-4 contact-form-input"
                        placeholder={t('contactFormEmail')}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        />
                    </div>
                    <div>
                        <textarea 
                            className='form-control contact-form-input' 
                            rows={3}
                            name='message'
                            value={message}
                            placeholder={t('contactFormMessage')}
                            onChange={(e) => setMessage(e.target.value)}
                            required>
                        </textarea>
                    </div>
                    <div>
                        <select className='form-control contact-form-input select-options'
                        name='volume'
                        onChange={(e) => setVolume(e.target.value)}
                        required>
                            <option value={''}>{t('monthlyOrder')}</option>
                            <option value={'0-500'}>0-500</option>
                            <option value={'501-1000'}>501-1000</option>
                            <option value={'1001-2000'}>1001-2000</option>
                            <option value={'2001+'}>2000+</option>
                        </select>
                    </div>
                    <div className="mt-3 ">
                        <button type="submit" className="mb-3 bg-white text-black text-lg py-3 px-5 rounded-full hover:bg-gray-200 transition-all duration-300 shadow-lg hover:shadow-xl btn-login-tw">{t('contactUs')}</button>
                    </div>
                    </form>
            </div>
          </div>
        </div>
        
        <div className='row'>
          <div className='col-sm-8'>
            <a href="/registerBusiness">
              <button className="btn-services-calculate-savings">{t('freeSevenDays')}</button>
            </a>
          </div>
          <div className='col-sm-4'>
            <a href="/appointment">
              <button className="btn-services-calculate-savings">{t('bookDemo')}</button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  )
}