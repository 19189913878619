import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../css/home.css'
import '../css/services.css'
import 'bootstrap'
import HomepageMenu from '../components/HomepageMenu';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import firstImage from '../images/backmarket-logo.png'
import bmlogo from '../images/bm-logo.png'
import bmVideo from '../images/backmarket-key.mp4'
import { useTranslation } from 'react-i18next';

export default function Guide() {
  const { t } = useTranslation();
  const [menuHeight, setMenuHeight] = useState(0);
  useEffect(() => {
    const menuElement = document.querySelector('.navbar');
    if (menuElement) {
      setMenuHeight(menuElement.offsetHeight);
    }
  }, []);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, []);

  return (
    <>
    <HomepageMenu />
    <Helmet>
        <title>{ t('automateBM')}</title>
                <meta name="description" content="Automate your Back Market invoicing process with Flexmerce. Streamline invoice generation, reduce errors, save time, and improve customer satisfaction. Start your free trial today!" />

            </Helmet>
      <div
        className="flex flex-col justify-between text-white relative overflow-hidden background-image mt-5rem"
        style={{ minHeight: `calc(100vh - ${menuHeight}px)` }}
      >
      <div className="container services-main-container">
        <div className='row landing-container'>
          
          <div className='col-sm-6 landing-container-image services-first-image-div rounded'>
            <img src={firstImage} alt='autopilot' className='services-first-image p-3 rounded' style={{ borderRadius: "20px !important" }} />
          </div>
          <div className='col-sm-6 landing-container-text'>
            <h1 className='first-title-container font-orange'>{ t('automateBM')}</h1>
            <p className='mt-4 font-light home-services-p'>
            { t('improveBM')}
            </p>
            <a href='/registerBusiness'>
              <button className='btn-services-calculate-savings'>
                  {t('freeTrialButton')}
              </button>
            </a>
            <a href='/appointment'>
              <button className='btn-services-contact'>
              { t('bookADemo')}
              </button>
            </a>
          </div>
        </div>

        <div className="testimonial-section mt-5 mb-2" id="backmarket">
            <div className="marketplace-tile services-why-choose-us-tile text-justify">
            <div className='services-main-container marketplace-page-integration-section'>
              <div className='text-center'>
              <div className="services-icon">
                <img src={bmlogo} alt='backmarketLogo' className='marketplace-logo mb-3' loading='lazy'/>
              </div>
              <h4 className='text-900 font-orange mb-4'>{ t('bmInt')}</h4>
              </div>
              <p className='home-services-p-1rem p-2'>{ t('ourBMInt')}</p>
                <ul className=''>
                {Array.from({ length: 5 }, (_, index) => (
                  <li className='home-services-p-1rem' key={index}>
                  {t(`bmIntegration.${index}.description`)}
                  </li>
                ))}
              </ul>
            </div>
            </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/calculatesavings">
              <button className="btn-services-calculate-savings">{ t('calculateBM')}</button>
          </a>
        </div>

        <div className="testimonial-section mt-5 mb-5" id="backmarket">
            <div className="marketplace-tile services-why-choose-us-tile">
                <h2 className="first-title-container font-orange text-center">{ t('getBackMarketKey')}</h2>
                <p className='mb-4 mt-2 text-center' dangerouslySetInnerHTML={{__html: t('seeHowBM')}} />
                <hr></hr>
                <div className='row'>
                    <div className='col-sm-8'>
                        <div className="video-container">
                            <video 
                                controls
                                className='guide-video'
                            >
                                <source src={bmVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='mt-3'>
                          <ol>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('loginBM')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('goTo')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('goToBM')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('clickBM')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('selectBM')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('clickConfirm')}
                            </li>
                            <li className='home-services-p-1rem text-light text-left'>
                            { t('yourAPI')}
                            </li>
                          </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContactSection />
      </div>
      <Footer />
    </div>
    </>
  )
}