// src/pages/Logout.js
import  { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../components/axio';

export default function Logout() {
  const navigate = useNavigate();
  const { logout } = useAuth();  // Use logout from context

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        await axiosInstance.post('/logout');
        const language = localStorage.getItem('currentLanguageCode') ?? 'en';
        localStorage.clear();
        logout(); 
        localStorage.setItem('currentLanguageCode', language)
        navigate('/login');
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };
    fetchUserInfo();
     // This should internally set isAuthenticated to false
  }, [navigate, logout]);  // Include logout in the dependency array

  return null; // Optionally, you can display a spinner or a "logging out" message
}
